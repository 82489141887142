import { Route } from "react-router-dom";

//? Pages
import AppContainer from "./AppContainer";
import LoginPage from "./components/LoginPage";
import LocationPage from "./components/location-page/LocationPage";

// Nested Routes.
import Galleria from "routes/galleria";
import SunCapital from 'routes/sun_capital';

// Lazy-loading components
import Header from "./components/common/header/Header";

function App() {
  return (
    <AppContainer>
      <Route path="/galleria">
        <Header />
        <Galleria />
      </Route>
      <Route path="/sun_capital">
        <Header />
        <SunCapital />
      </Route>
      <Route path="/location-page">
        <Header />
        <LocationPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/">
        <LoginPage destinationToUrl="/location-page" />
      </Route>
    </AppContainer>
  );
}

export default App;

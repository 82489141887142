import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type Images = {
  isGalleriaImgActive: boolean;
  isSunCapitalActive: boolean;
};

const initialState: Images = {
  isGalleriaImgActive: false,
  isSunCapitalActive: false,
};

const masterPlanImgSlice = createSlice({
  name: "masterPlanImg",
  initialState,
  reducers: {
    activeGalleria(state: Images) {
      state.isGalleriaImgActive = true;
    },

    idleGalleria(state: Images) {
      state.isGalleriaImgActive = false;
    },
    activeSunCapital(state: Images) {
      state.isSunCapitalActive = true;
    },

    idleSunCapital(state: Images) {
      state.isSunCapitalActive = false;
    },
  },
});

// Reducers
export const { activeGalleria, idleGalleria } = masterPlanImgSlice.actions;
export const { idleSunCapital, activeSunCapital } = masterPlanImgSlice.actions;

export default masterPlanImgSlice.reducer;

export const selectMasterPlanImgs = (state: RootState) => state.masterPlanImg;
export const isGalleriaImgActive = (state: RootState) =>
  state.masterPlanImg.isGalleriaImgActive;
export const isSunCapitalImgActive = (state: RootState) =>
  state.masterPlanImg.isSunCapitalActive;

import { Route } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/sun_capital/master-plane/Elio/Type 5/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/sun_capital/master-plane/Elio/Type 5/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/sun_capital/master-plane/Elio/Type 5/payment/PaymentPage';

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/elio/type-5/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/elio/type-5/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/elio/type-5/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

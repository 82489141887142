import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/galleria/master-plane/zone-2/Type-H/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/galleria/master-plane/zone-2/Type-H/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/galleria/master-plane/zone-2/Type-H/payment/PaymentPage';
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-2/type-h/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-2/type-h/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-2/type-h/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

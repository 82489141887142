import { Box, Image, Skeleton } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import { MasterPlanImageContext } from '../ProjectZonePage';

import ZoneImg from 'img/sun-capital/Master plan/Zone elio final/Elio/Elio zone.jpg';
import ZoneType5 from 'img/sun-capital/Master plan/Zone elio final/Elio/Elio 5.jpg';
import ZoneType15 from 'img/sun-capital/Master plan/Zone elio final/Elio/Elio-15.jpg';

export default function Img() {
  const [isLoaded, setIsLoaded] = useState(false);

  const { typeName } = useContext(MasterPlanImageContext);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
          objectFit="cover"
          src={selectImage(typeName)}
        />
      </Box>
    </Skeleton>
  );
}

function selectImage(zoneType: string) {
  switch (zoneType) {
    case 'type-5':
      return ZoneType5;
    case 'type-15':
      return ZoneType15;
    default:
      return ZoneImg;
  }
}

import { Icon, Image } from "@chakra-ui/react";
import GoogleMapComp from "components/common/google-map-comp/GoogleMapComp";
import MarkerIcon from "img/icons/Marker-Icon-Bg-White.png";
import Pin from "../pins/Pin";
import { IconsNames } from "../pins/utils";
import { useContext } from "react";
import { LocationPageContext } from "../ProjectLocationPage";
import { IoMedkit } from "react-icons/io5";

export default function Map() {
  const { activeIconName, setActiveIconName } = useContext(LocationPageContext);

  return (
    <GoogleMapComp sun={true}>
      <Image
        lat={30.01148915262006}
        lng={31.555827276987642}
        src={MarkerIcon}
        w={["70px", "35px", "35px", "35px", "35px", "70px"]}
        h={["70px", "35px", "35px", "35px", "35px", "70px"]}
      />
      {/* Landmarks */}
      <Pin
        lat={30.007645994585292}
        lng={31.119803598332158}
        iconName={IconsNames.Landmarks}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Landmarks" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Landmarks")}
        >
          <g id="Landmarks-Icon" transform="translate(0.001 0)">
            <path
              id="Path_12484"
              data-name="Path 12484"
              d="M310.816,150.388a.7.7,0,0,0-.629-.389h-6.375v2.109a2.112,2.112,0,0,1-2.109,2.109H301v2.109a.7.7,0,0,0,.7.7h8.484a.7.7,0,0,0,.562-1.125l-1.793-2.391,1.793-2.391A.7.7,0,0,0,310.816,150.388Zm0,0"
              transform="translate(-286.891 -142.969)"
              fill={activeIconName === "Landmarks" ? "#E53E3E" : "#000"}
            />
            <path
              id="Path_12485"
              data-name="Path 12485"
              d="M23.3,22.594H12.606c-.4-1.461-2.035-2.557-4.122-2.763V9.844h6.328a.7.7,0,0,0,.7-.7V3.515a.7.7,0,0,0-.7-.7H9.761a2.086,2.086,0,0,0,.129-.7,2.109,2.109,0,1,0-2.812,1.98V19.83c-2.088.207-3.722,1.3-4.122,2.763H.7A.7.7,0,0,0,.7,24H23.3a.7.7,0,0,0,0-1.406Zm0,0"
              transform="translate(0)"
              fill={activeIconName === "Landmarks" ? "#E53E3E" : "#000"}
            />
          </g>
        </Icon>
      </Pin>
      {/* Retail */}
      <Pin
        lat={29.97188312334981}
        lng={31.016702864411908}
        iconName={IconsNames.Retail}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Retail" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Retail")}
        >
          <defs>
          <clipPath id="a">
            <rect
              width="24"
              height="24"
              transform="translate(141 1658)"
              fill={activeIconName === "Retail" ? "#E53E3E" : "#000"}
            />
          </clipPath>
        </defs>
        <g transform="translate(-141 -1658)" clip-path="url(#a)">
          <g transform="translate(141 1659.453)">
            <path
              d="M7.781,14.063h12.7a.7.7,0,0,0,.676-.51l2.813-9.844a.7.7,0,0,0-.676-.9H6.158L5.655.551A.7.7,0,0,0,4.969,0H.7a.7.7,0,1,0,0,1.406H4.4L6.944,12.832a2.108,2.108,0,0,0,.838,4.043h12.7a.7.7,0,1,0,0-1.406H7.781a.7.7,0,1,1,0-1.406Zm0,0"
              fill={activeIconName === "Retail" ? "#E53E3E" : "#000"}
            />
            <path
              d="M7.078,18.984a2.109,2.109,0,1,0,2.11-2.109A2.112,2.112,0,0,0,7.078,18.984Zm0,0"
              fill={activeIconName === "Retail" ? "#E53E3E" : "#000"}
            />
            <path
              d="M16.969,18.984a2.109,2.109,0,1,0,2.109-2.109A2.112,2.112,0,0,0,16.969,18.984Zm0,0"
              fill={activeIconName === "Retail" ? "#E53E3E" : "#000"}
            />
          </g>
        </g>
        </Icon>
      </Pin>
      {/* Education */}
      <Pin
        lat={29.9218996494741}
        lng={31.08793472438361}
        iconName={IconsNames.Education}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Education" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Education")}
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_5122"
                data-name="Rectangle 5122"
                width="24"
                height="24"
                transform="translate(430 1658)"
                fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
              />
            </clipPath>
          </defs>
          <g
            id="Education-Icon"
            transform="translate(-430 -1658)"
            clip-path="url(#clip-path)"
          >
            <g id="mortarboard" transform="translate(430 1658)">
              <path
                id="Path_12439"
                data-name="Path 12439"
                d="M22.494,15.931V8.466l-1.406.469v7a2.213,2.213,0,0,0-1.506,2.094v3.766a.7.7,0,0,0,.7.7H23.3a.7.7,0,0,0,.7-.7V18.025A2.213,2.213,0,0,0,22.494,15.931Z"
                fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
              />
              <path
                id="Path_12440"
                data-name="Path 12440"
                d="M11.333,11.742,4.519,9.47v4.789a.7.7,0,0,0,.206.5,10.289,10.289,0,0,0,14.551,0,.7.7,0,0,0,.206-.5V9.47l-6.814,2.271a2.11,2.11,0,0,1-1.334,0Z"
                fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
              />
              <path
                id="Path_12441"
                data-name="Path 12441"
                d="M12,10.444a.7.7,0,0,0,.222-.036l11.3-3.766a.7.7,0,0,0,0-1.334l-11.3-3.766a.7.7,0,0,0-.445,0L.481,5.308a.7.7,0,0,0,0,1.334l11.3,3.766a.7.7,0,0,0,.222.036Z"
                fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
              />
            </g>
          </g>
        </Icon>
      </Pin>
      <Pin
        lat={29.944717181446595}
        lng={31.07364970870198}
        iconName={IconsNames.Education}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Education" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Education")}
        >
          <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_5122"
              data-name="Rectangle 5122"
              width="24"
              height="24"
              transform="translate(430 1658)"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
          </clipPath>
        </defs>
        <g
          id="Education-Icon"
          transform="translate(-430 -1658)"
          clip-path="url(#clip-path)"
        >
          <g id="mortarboard" transform="translate(430 1658)">
            <path
              id="Path_12439"
              data-name="Path 12439"
              d="M22.494,15.931V8.466l-1.406.469v7a2.213,2.213,0,0,0-1.506,2.094v3.766a.7.7,0,0,0,.7.7H23.3a.7.7,0,0,0,.7-.7V18.025A2.213,2.213,0,0,0,22.494,15.931Z"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
            <path
              id="Path_12440"
              data-name="Path 12440"
              d="M11.333,11.742,4.519,9.47v4.789a.7.7,0,0,0,.206.5,10.289,10.289,0,0,0,14.551,0,.7.7,0,0,0,.206-.5V9.47l-6.814,2.271a2.11,2.11,0,0,1-1.334,0Z"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
            <path
              id="Path_12441"
              data-name="Path 12441"
              d="M12,10.444a.7.7,0,0,0,.222-.036l11.3-3.766a.7.7,0,0,0,0-1.334l-11.3-3.766a.7.7,0,0,0-.445,0L.481,5.308a.7.7,0,0,0,0,1.334l11.3,3.766a.7.7,0,0,0,.222.036Z"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
          </g>
        </g>
        </Icon>
      </Pin>
      <Pin
        lat={29.945212480956652}
        lng={31.06385883492794}
        iconName={IconsNames.Education}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Education" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Education")}
        >
          <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_5122"
              data-name="Rectangle 5122"
              width="24"
              height="24"
              transform="translate(430 1658)"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
          </clipPath>
        </defs>
        <g
          id="Education-Icon"
          transform="translate(-430 -1658)"
          clip-path="url(#clip-path)"
        >
          <g id="mortarboard" transform="translate(430 1658)">
            <path
              id="Path_12439"
              data-name="Path 12439"
              d="M22.494,15.931V8.466l-1.406.469v7a2.213,2.213,0,0,0-1.506,2.094v3.766a.7.7,0,0,0,.7.7H23.3a.7.7,0,0,0,.7-.7V18.025A2.213,2.213,0,0,0,22.494,15.931Z"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
            <path
              id="Path_12440"
              data-name="Path 12440"
              d="M11.333,11.742,4.519,9.47v4.789a.7.7,0,0,0,.206.5,10.289,10.289,0,0,0,14.551,0,.7.7,0,0,0,.206-.5V9.47l-6.814,2.271a2.11,2.11,0,0,1-1.334,0Z"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
            <path
              id="Path_12441"
              data-name="Path 12441"
              d="M12,10.444a.7.7,0,0,0,.222-.036l11.3-3.766a.7.7,0,0,0,0-1.334l-11.3-3.766a.7.7,0,0,0-.445,0L.481,5.308a.7.7,0,0,0,0,1.334l11.3,3.766a.7.7,0,0,0,.222.036Z"
              fill={activeIconName === "Education" ? "#E53E3E" : "#000"}
            />
          </g>
        </g>
        </Icon>
      </Pin>
      <Pin
        lat={30.017538077677287}
        lng={31.066211606879985}
        iconName={IconsNames.Education}
      >
        <Icon
          viewBox="0 0 65.9 74.8"
          fontSize="2rem"
          color={activeIconName === "Medical" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Medical")}
        >
          <g id="Medical" transform="translate(-145.2 -925.4)">
            <g id="Group_3959" data-name="Group 3959">
              <path
                id="Path_12748"
                data-name="Path 12748"
                d="M193.3,986.7h4.3v-4.3h4.3v-4.3h-4.3v-4.3h-4.3v4.3H189v4.3h4.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12749"
                data-name="Path 12749"
                d="M154.3,947.8h30.3v4.3H154.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12750"
                data-name="Path 12750"
                d="M154.3,956.4h26v4.3h-26Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12751"
                data-name="Path 12751"
                d="M154.3,965.1h17.3v4.3H154.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12752"
                data-name="Path 12752"
                d="M154.3,973.8h17.3v4.3H154.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12844"
                data-name="Path 12844"
                d="M189.5,91.5l-15.7-8.2a1.118,1.118,0,1,0-1,2l15.7,8.2a.5.5,0,0,1,0,.8l-15.6,8.2a6.2,6.2,0,0,0-2.2-1.7l-11.5-5.9a4.191,4.191,0,0,0,.4-1.5c0-2.4-2.5-4.2-5.7-4.2s-5.7,1.9-5.7,4.2c0,2.4,2.5,4.2,5.7,4.2a6.563,6.563,0,0,0,3.8-1.1l12.1,6.2a8.341,8.341,0,0,1,1.2.8c-.5.3-15,7.9-15.7,8.2a2.8,2.8,0,0,1-2.8,0c-4-2.1-14.6-7.7-33.2-17.4a.5.5,0,0,1,0-.8L152.4,76a2.8,2.8,0,0,1,2.8,0l13,6.8a1.118,1.118,0,0,0,1-2l-13-6.8a5.1,5.1,0,0,0-4.8,0L118.2,91.4a2.438,2.438,0,0,0-1.4,2.3,2.542,2.542,0,0,0,1.4,2.3l8.5,4.5v19.3a3.007,3.007,0,0,0,2.5,3,44.688,44.688,0,0,1,20,9.5,7.377,7.377,0,0,0,9.3,0,46.76,46.76,0,0,1,13.9-7.9v3a4.5,4.5,0,0,0-3.4,4.4v3.7h-.4a1.1,1.1,0,0,0,0,2.2h.2l-.5,2.7a1.118,1.118,0,0,0,2.2.4l.6-3.1h1.4v2.9a1.1,1.1,0,0,0,2.2,0v-2.9h1.4l.6,3.1a1.118,1.118,0,1,0,2.2-.4l-.5-2.7h.2a1.1,1.1,0,0,0,0-2.2H178v-3.7a4.618,4.618,0,0,0-3.4-4.4v-3.6a27.437,27.437,0,0,1,3.9-.9,3.1,3.1,0,0,0,2.5-3v-6.2a1.1,1.1,0,0,0-2.2,0v6.2a.961.961,0,0,1-.7.9c-1.2.2-2.3.5-3.5.8V107a7.635,7.635,0,0,0-.5-2.7l4.8-2.5v6.8a1.1,1.1,0,0,0,2.2,0v-7.9l8.5-4.5a2.438,2.438,0,0,0,1.4-2.3,3.185,3.185,0,0,0-1.5-2.4Zm-39.2,1.6c0-1,1.5-2.1,3.5-2.1,2.1,0,3.5,1.1,3.5,2.1s-1.5,2.1-3.5,2.1C151.7,95.2,150.3,94.1,150.3,93.1Zm25.5,38.6v3.7h-4.7v-3.7a2.263,2.263,0,0,1,2.3-2.3A2.348,2.348,0,0,1,175.8,131.7Zm-3.4-24.9v15.3a45.165,45.165,0,0,0-15.3,8.5,5,5,0,0,1-6.5,0,47.077,47.077,0,0,0-21-10,.876.876,0,0,1-.7-.9V101.6c.9.4,22.1,11.6,22.5,11.8a5.1,5.1,0,0,0,4.8,0l15.8-8.3A3.533,3.533,0,0,1,172.4,106.8Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
            </g>
          </g>
        </Icon>
      </Pin>
      <Pin
        lat={30.02118592359534}
        lng={31.071915315344004}
        iconName={IconsNames.Education}
      >
        <Icon
          viewBox="0 0 65.9 74.8"
          fontSize="2rem"
          color={activeIconName === "Medical" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Medical")}
        >
          <g id="Medical" transform="translate(-145.2 -925.4)">
            <g id="Group_3959" data-name="Group 3959">
              <path
                id="Path_12748"
                data-name="Path 12748"
                d="M193.3,986.7h4.3v-4.3h4.3v-4.3h-4.3v-4.3h-4.3v4.3H189v4.3h4.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12749"
                data-name="Path 12749"
                d="M154.3,947.8h30.3v4.3H154.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12750"
                data-name="Path 12750"
                d="M154.3,956.4h26v4.3h-26Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12751"
                data-name="Path 12751"
                d="M154.3,965.1h17.3v4.3H154.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12752"
                data-name="Path 12752"
                d="M154.3,973.8h17.3v4.3H154.3Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                id="Path_12844"
                data-name="Path 12844"
                d="M189.5,91.5l-15.7-8.2a1.118,1.118,0,1,0-1,2l15.7,8.2a.5.5,0,0,1,0,.8l-15.6,8.2a6.2,6.2,0,0,0-2.2-1.7l-11.5-5.9a4.191,4.191,0,0,0,.4-1.5c0-2.4-2.5-4.2-5.7-4.2s-5.7,1.9-5.7,4.2c0,2.4,2.5,4.2,5.7,4.2a6.563,6.563,0,0,0,3.8-1.1l12.1,6.2a8.341,8.341,0,0,1,1.2.8c-.5.3-15,7.9-15.7,8.2a2.8,2.8,0,0,1-2.8,0c-4-2.1-14.6-7.7-33.2-17.4a.5.5,0,0,1,0-.8L152.4,76a2.8,2.8,0,0,1,2.8,0l13,6.8a1.118,1.118,0,0,0,1-2l-13-6.8a5.1,5.1,0,0,0-4.8,0L118.2,91.4a2.438,2.438,0,0,0-1.4,2.3,2.542,2.542,0,0,0,1.4,2.3l8.5,4.5v19.3a3.007,3.007,0,0,0,2.5,3,44.688,44.688,0,0,1,20,9.5,7.377,7.377,0,0,0,9.3,0,46.76,46.76,0,0,1,13.9-7.9v3a4.5,4.5,0,0,0-3.4,4.4v3.7h-.4a1.1,1.1,0,0,0,0,2.2h.2l-.5,2.7a1.118,1.118,0,0,0,2.2.4l.6-3.1h1.4v2.9a1.1,1.1,0,0,0,2.2,0v-2.9h1.4l.6,3.1a1.118,1.118,0,1,0,2.2-.4l-.5-2.7h.2a1.1,1.1,0,0,0,0-2.2H178v-3.7a4.618,4.618,0,0,0-3.4-4.4v-3.6a27.437,27.437,0,0,1,3.9-.9,3.1,3.1,0,0,0,2.5-3v-6.2a1.1,1.1,0,0,0-2.2,0v6.2a.961.961,0,0,1-.7.9c-1.2.2-2.3.5-3.5.8V107a7.635,7.635,0,0,0-.5-2.7l4.8-2.5v6.8a1.1,1.1,0,0,0,2.2,0v-7.9l8.5-4.5a2.438,2.438,0,0,0,1.4-2.3,3.185,3.185,0,0,0-1.5-2.4Zm-39.2,1.6c0-1,1.5-2.1,3.5-2.1,2.1,0,3.5,1.1,3.5,2.1s-1.5,2.1-3.5,2.1C151.7,95.2,150.3,94.1,150.3,93.1Zm25.5,38.6v3.7h-4.7v-3.7a2.263,2.263,0,0,1,2.3-2.3A2.348,2.348,0,0,1,175.8,131.7Zm-3.4-24.9v15.3a45.165,45.165,0,0,0-15.3,8.5,5,5,0,0,1-6.5,0,47.077,47.077,0,0,0-21-10,.876.876,0,0,1-.7-.9V101.6c.9.4,22.1,11.6,22.5,11.8a5.1,5.1,0,0,0,4.8,0l15.8-8.3A3.533,3.533,0,0,1,172.4,106.8Z"
                fill="none"
                stroke={activeIconName === "Medical" ? "#E53E3E" : "#000"}
                stroke-miterlimit="10"
                stroke-width="1"
              />
            </g>
          </g>
        </Icon>
      </Pin>
      {/* Medical */}
      <Pin
        lat={29.948519947493974}
        lng={31.097561069312}
        iconName={IconsNames.Medical}
      >
        <Icon as={IoMedkit} fontSize="2rem" color={activeIconName === "Medical" ? "#E53E3E" : "#000"} />
      </Pin>
      <Pin
        lat={29.95757446672573}
        lng={31.07859288268674}
        iconName={IconsNames.Medical}
      >
        <Icon as={IoMedkit} fontSize="2rem" color={activeIconName === "Medical" ? "#E53E3E" : "#000"} />
      </Pin>
      <Pin
        lat={29.961312514768952}
        lng={31.043965360895573}
        iconName={IconsNames.Medical}
      >
        <Icon as={IoMedkit} fontSize="2rem" color={activeIconName === "Medical" ? "#E53E3E" : "#000"} />
      </Pin>
      {/* Attractions */}
      <Pin
        lat={29.93824130681054}
        lng={31.07544098729605}
        iconName={IconsNames.Attractions}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Attractions" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Attractions")}
        >
          <g id="Attractions-Icon" transform="translate(0)">
            <g id="Group_3853" data-name="Group 3853" transform="translate(0 0)">
              <path
                id="Path_12486"
                data-name="Path 12486"
                d="M23.3,22.594H16.265l-.979-3.084a8.657,8.657,0,0,0,1.352-.7,2.109,2.109,0,1,0,2.686-2.686,8.621,8.621,0,0,0,1.137-2.737,2.109,2.109,0,1,0,0-3.8,8.623,8.623,0,0,0-1.137-2.737A2.109,2.109,0,1,0,16.638,4.16,8.623,8.623,0,0,0,13.9,3.023a2.109,2.109,0,1,0-3.8,0A8.624,8.624,0,0,0,7.362,4.16,2.109,2.109,0,1,0,4.676,6.846,8.622,8.622,0,0,0,3.539,9.583a2.109,2.109,0,1,0,0,3.8,8.621,8.621,0,0,0,1.137,2.737,2.109,2.109,0,1,0,2.686,2.686,8.657,8.657,0,0,0,1.352.7l-.979,3.084H.7A.7.7,0,0,0,.7,24H23.3a.7.7,0,1,0,0-1.406Zm-5.688-6.5-1-1a5.832,5.832,0,0,0,1.208-2.911h1.414A7.234,7.234,0,0,1,17.608,16.1Zm0-9.228a7.233,7.233,0,0,1,1.623,3.911H17.817A5.832,5.832,0,0,0,16.609,7.87ZM12.7,4.253a7.233,7.233,0,0,1,3.911,1.623l-1,1A5.832,5.832,0,0,0,12.7,5.667V4.253Zm3.75,7.231A4.458,4.458,0,0,1,14,15.462l-.729-2.3a2.109,2.109,0,1,0-2.543,0L10,15.462a4.453,4.453,0,1,1,6.454-3.978Zm-3.793,4.4a4.472,4.472,0,0,1-1.321,0l.66-2.08ZM12,17.344a5.866,5.866,0,0,0,1.09-.1l.428,1.348a7.28,7.28,0,0,1-3.036,0l.428-1.348a5.867,5.867,0,0,0,1.09.1ZM11.3,4.253V5.667A5.832,5.832,0,0,0,8.386,6.876l-1-1A7.233,7.233,0,0,1,11.3,4.253ZM6.392,6.87l1,1a5.832,5.832,0,0,0-1.208,2.911H4.769A7.233,7.233,0,0,1,6.392,6.87Zm0,9.228a7.233,7.233,0,0,1-1.623-3.911H6.183A5.832,5.832,0,0,0,7.391,15.1Zm.994.994,1-1a5.869,5.869,0,0,0,1.184.722l-.428,1.348a7.279,7.279,0,0,1-1.756-1.071Zm1.825,5.5.844-2.658a8.706,8.706,0,0,0,3.891,0l.844,2.658Zm5.219-5.778a5.869,5.869,0,0,0,1.184-.722l1,1a7.276,7.276,0,0,1-1.756,1.071Z"
                transform="translate(0 0)"
                fill={activeIconName === "Attractions" ? "#E53E3E" : "#000"}
              />
            </g>
          </g>
        </Icon>
      </Pin>
      {/* Connectivity */}
      <Pin
        lat={29.936605347401052}
        lng={31.09706049813914}
        iconName={IconsNames.Connectivity}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Connectivity")}
        >
          <g id="Connectivity-Icon" transform="translate(-0.538 -0.538)">
            <g id="Layer_x0020_1" transform="translate(0.538 0.538)">
              <g id="_218022968">
                <path
                  id="_218023232"
                  d="M10.292,4.282H.538V8.2h9.753a.288.288,0,0,1,.288.288V24.538H14.5V21.3a.288.288,0,0,1,.288-.288h9.754V17.093H14.785a.287.287,0,0,1-.288-.287V.538H10.58V3.994a.288.288,0,0,1-.288.288ZM8.8,6.24a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1A.288.288,0,0,1,8.8,6.24Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1a.288.288,0,0,1,.288.288Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576H3.039a.288.288,0,0,1,.288.288ZM16.568,18.764h1a.288.288,0,1,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-4.2.843V18.418a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm0-3.237V15.181a.288.288,0,1,1,.576,0V16.37a.288.288,0,0,1-.576,0Zm0-3.237V11.944a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-2.949a.288.288,0,0,1-.288-.288V8.706a.288.288,0,1,1,.576,0V9.9A.288.288,0,0,1,12.657,10.184Zm0-3.237a.288.288,0,0,1-.288-.288V5.469a.288.288,0,1,1,.576,0V6.658A.288.288,0,0,1,12.657,6.946ZM19.3,18.764h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm2.735,0h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-9.669,4.081V21.655a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-19.135a.288.288,0,0,1-.288-.288V2.232a.288.288,0,1,1,.576,0V3.421A.288.288,0,0,1,12.657,3.709Z"
                  transform="translate(-0.538 -0.538)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218023352"
                  d="M5.079,2.466A1.381,1.381,0,1,0,6.46,1.086a1.383,1.383,0,0,0-1.381,1.38Z"
                  transform="translate(13.873 1.2)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218022920"
                  d="M4.531,4.2c0,1,1.928,4.809,3.08,7.087.22.435.421.833.586,1.165.165-.332.366-.73.586-1.165C9.936,9.014,11.865,5.2,11.865,4.2a3.667,3.667,0,0,0-7.333,0ZM8.2,6.161A1.957,1.957,0,1,1,10.155,4.2,1.959,1.959,0,0,1,8.2,6.161Z"
                  transform="translate(12.135 -0.538)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218023040"
                  d="M2.467,3.892A1.381,1.381,0,1,0,3.848,5.273,1.382,1.382,0,0,0,2.467,3.892Z"
                  transform="translate(1.2 10.105)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218022824"
                  d="M4.2,8.968A1.957,1.957,0,1,1,6.161,7.011,1.959,1.959,0,0,1,4.2,8.968Zm0-5.624A3.671,3.671,0,0,0,.538,7.011c0,1,1.928,4.809,3.08,7.086.22.435.422.833.586,1.166.165-.332.366-.73.586-1.165,1.152-2.277,3.08-6.089,3.08-7.087A3.671,3.671,0,0,0,4.2,3.344Z"
                  transform="translate(-0.538 8.366)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
              </g>
            </g>
          </g>
        </Icon>
      </Pin>
      <Pin
        lat={30.04103588666138}
        lng={31.551042776223717}
        iconName={IconsNames.Attractions}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Connectivity")}
        >
          <g id="Connectivity-Icon" transform="translate(-0.538 -0.538)">
            <g id="Layer_x0020_1" transform="translate(0.538 0.538)">
              <g id="_218022968">
                <path
                  id="_218023232"
                  d="M10.292,4.282H.538V8.2h9.753a.288.288,0,0,1,.288.288V24.538H14.5V21.3a.288.288,0,0,1,.288-.288h9.754V17.093H14.785a.287.287,0,0,1-.288-.287V.538H10.58V3.994a.288.288,0,0,1-.288.288ZM8.8,6.24a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1A.288.288,0,0,1,8.8,6.24Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1a.288.288,0,0,1,.288.288Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576H3.039a.288.288,0,0,1,.288.288ZM16.568,18.764h1a.288.288,0,1,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-4.2.843V18.418a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm0-3.237V15.181a.288.288,0,1,1,.576,0V16.37a.288.288,0,0,1-.576,0Zm0-3.237V11.944a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-2.949a.288.288,0,0,1-.288-.288V8.706a.288.288,0,1,1,.576,0V9.9A.288.288,0,0,1,12.657,10.184Zm0-3.237a.288.288,0,0,1-.288-.288V5.469a.288.288,0,1,1,.576,0V6.658A.288.288,0,0,1,12.657,6.946ZM19.3,18.764h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm2.735,0h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-9.669,4.081V21.655a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-19.135a.288.288,0,0,1-.288-.288V2.232a.288.288,0,1,1,.576,0V3.421A.288.288,0,0,1,12.657,3.709Z"
                  transform="translate(-0.538 -0.538)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218023352"
                  d="M5.079,2.466A1.381,1.381,0,1,0,6.46,1.086a1.383,1.383,0,0,0-1.381,1.38Z"
                  transform="translate(13.873 1.2)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218022920"
                  d="M4.531,4.2c0,1,1.928,4.809,3.08,7.087.22.435.421.833.586,1.165.165-.332.366-.73.586-1.165C9.936,9.014,11.865,5.2,11.865,4.2a3.667,3.667,0,0,0-7.333,0ZM8.2,6.161A1.957,1.957,0,1,1,10.155,4.2,1.959,1.959,0,0,1,8.2,6.161Z"
                  transform="translate(12.135 -0.538)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218023040"
                  d="M2.467,3.892A1.381,1.381,0,1,0,3.848,5.273,1.382,1.382,0,0,0,2.467,3.892Z"
                  transform="translate(1.2 10.105)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218022824"
                  d="M4.2,8.968A1.957,1.957,0,1,1,6.161,7.011,1.959,1.959,0,0,1,4.2,8.968Zm0-5.624A3.671,3.671,0,0,0,.538,7.011c0,1,1.928,4.809,3.08,7.086.22.435.422.833.586,1.166.165-.332.366-.73.586-1.165,1.152-2.277,3.08-6.089,3.08-7.087A3.671,3.671,0,0,0,4.2,3.344Z"
                  transform="translate(-0.538 8.366)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
              </g>
            </g>
          </g>
        </Icon>
      </Pin>
      <Pin
        lat={29.947897390164492}
        lng={31.097125810476715}
        iconName={IconsNames.Connectivity}
      >
        <Icon
          viewBox="0 0 24 24"
          fontSize="2rem"
          color={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
          onClick={() => setActiveIconName("Connectivity")}
        >
          <g id="Connectivity-Icon" transform="translate(-0.538 -0.538)">
            <g id="Layer_x0020_1" transform="translate(0.538 0.538)">
              <g id="_218022968">
                <path
                  id="_218023232"
                  d="M10.292,4.282H.538V8.2h9.753a.288.288,0,0,1,.288.288V24.538H14.5V21.3a.288.288,0,0,1,.288-.288h9.754V17.093H14.785a.287.287,0,0,1-.288-.287V.538H10.58V3.994a.288.288,0,0,1-.288.288ZM8.8,6.24a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1A.288.288,0,0,1,8.8,6.24Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1a.288.288,0,0,1,.288.288Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576H3.039a.288.288,0,0,1,.288.288ZM16.568,18.764h1a.288.288,0,1,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-4.2.843V18.418a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm0-3.237V15.181a.288.288,0,1,1,.576,0V16.37a.288.288,0,0,1-.576,0Zm0-3.237V11.944a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-2.949a.288.288,0,0,1-.288-.288V8.706a.288.288,0,1,1,.576,0V9.9A.288.288,0,0,1,12.657,10.184Zm0-3.237a.288.288,0,0,1-.288-.288V5.469a.288.288,0,1,1,.576,0V6.658A.288.288,0,0,1,12.657,6.946ZM19.3,18.764h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm2.735,0h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-9.669,4.081V21.655a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-19.135a.288.288,0,0,1-.288-.288V2.232a.288.288,0,1,1,.576,0V3.421A.288.288,0,0,1,12.657,3.709Z"
                  transform="translate(-0.538 -0.538)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218023352"
                  d="M5.079,2.466A1.381,1.381,0,1,0,6.46,1.086a1.383,1.383,0,0,0-1.381,1.38Z"
                  transform="translate(13.873 1.2)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218022920"
                  d="M4.531,4.2c0,1,1.928,4.809,3.08,7.087.22.435.421.833.586,1.165.165-.332.366-.73.586-1.165C9.936,9.014,11.865,5.2,11.865,4.2a3.667,3.667,0,0,0-7.333,0ZM8.2,6.161A1.957,1.957,0,1,1,10.155,4.2,1.959,1.959,0,0,1,8.2,6.161Z"
                  transform="translate(12.135 -0.538)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218023040"
                  d="M2.467,3.892A1.381,1.381,0,1,0,3.848,5.273,1.382,1.382,0,0,0,2.467,3.892Z"
                  transform="translate(1.2 10.105)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
                <path
                  id="_218022824"
                  d="M4.2,8.968A1.957,1.957,0,1,1,6.161,7.011,1.959,1.959,0,0,1,4.2,8.968Zm0-5.624A3.671,3.671,0,0,0,.538,7.011c0,1,1.928,4.809,3.08,7.086.22.435.422.833.586,1.166.165-.332.366-.73.586-1.165,1.152-2.277,3.08-6.089,3.08-7.087A3.671,3.671,0,0,0,4.2,3.344Z"
                  transform="translate(-0.538 8.366)"
                  fill={activeIconName === "Connectivity" ? "#E53E3E" : "#000"}
                />
              </g>
            </g>
          </g>
        </Icon>
      </Pin>
    </GoogleMapComp>
  );
}

import { Route } from "react-router-dom";

// Pages.
import MasterPlanPage from 'pages/sun_capital/master-plane/ProjectMasterPlanePage';

import Cyra from "./cyra";
import Aurora from "./aurora";
import UGreen from "./u_green";
import HaniSaad from "./hani_saad";
import Horizontal from "./horizontal";
import CapitalHeart from "./capital_heart";
import Elio from "./elio";

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan`}>
        <MasterPlanPage />
      </Route>
      <Cyra />
      <Aurora />
      <UGreen />
      <HaniSaad />
      <Horizontal />
      <CapitalHeart />
      <Elio />
    </>
  );
};

export default Index;

import { useRouteMatch } from "react-router-dom";

// Custom Components.
import OptionsRow from "./OptionsRow";
import BuildingOption from "./BuildingOption";
import OptionsContainer from "./OptionsContainer";

// Images.
import PropertyTypeB from "img/galleria/master-plan/zone-7/Type B main cover.png";
import PropertyTypeD from "img/galleria/master-plan/zone-7/Type D main cover.png";

export default function AllBuildings() {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-b"
          planUrl={`${path}/type-b/plan`}
          buildingName="Type B"
          image={PropertyTypeB}
        />
        <BuildingOption
          typeName="type-d"
          planUrl={`${path}/type-d/plan`}
          buildingName="Type D"
          image={PropertyTypeD}
        />
      </OptionsRow>
    </OptionsContainer>
  );
}

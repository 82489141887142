import { Box, Image, Skeleton } from "@chakra-ui/react";
import { useContext, useState } from "react";

import { MasterPlanImageContext } from "../ProjectZonePage";

import ZoneImg from "img/sun-capital/Master plan/The Capital Heart/Capital Heart Zone.jpg";
import ZoneTypeC from "img/sun-capital/Master plan/The Capital Heart/types/Fairmont Hotel.jpg";
import ZoneTypeE from "img/sun-capital/Master plan/The Capital Heart/types/Untitled-1-03.jpg";
import ZoneTypeF from "img/sun-capital/Master plan/The Capital Heart/types/Untitled-1-04.jpg";
import ZoneTypeG from "img/sun-capital/Master plan/The Capital Heart/types/Untitled-1-05.jpg";

export default function Img() {
  const [isLoaded, setIsLoaded] = useState(false);

  const { typeName } = useContext(MasterPlanImageContext);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "3rem" }}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "3rem" }}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "3rem" }}
          src={selectImage(typeName)}
        />
      </Box>
    </Skeleton>
  );
}

function selectImage(zoneType: string) {
  switch (zoneType) {
    case "type-c":
      return ZoneTypeC;
    case "type-f":
      return ZoneTypeF;
    case "type-e":
      return ZoneTypeE;
    case "type-g":
      return ZoneTypeG;
    default:
      return ZoneImg;
  }
}

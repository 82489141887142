import { VStack } from "@chakra-ui/react";

export default function OptionsContainer(props: any) {
  return (
    <VStack
      w="100%"
      h="43rem"
      overflowY="auto"
      spacing={{
        base: "1vh",
        tablet16: "1vh",
      }}
    >
      {props.children}
    </VStack>
  );
}

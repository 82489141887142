import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export interface FormInputProps {
  placeholderText: string;
  registerText: string;
  registerOptions?: any;
  inputType?: string;
  formRegisterFunc: (txt: string | any, opt?: any) => any;
}

export const FormInput = (props: FormInputProps) => {
  return (
    <FormControl h="full" w="full">
      <FormLabel color="white" fontSize="1.5rem" fontWeight="200">
        {props.placeholderText}
      </FormLabel>
      <Input
        required
        h="50%"
        px="1rem"
        py="0.5rem"
        type={props.inputType}
        borderColor="white"
        borderWidth="1px"
        borderRadius="20px"
        placeholder={props.placeholderText}
        {...props.formRegisterFunc(props.registerText, props.registerOptions)}
        style={{
          padding: "1rem 1rem",
          fontSize: "1.2rem",
          fontWeight: 500,
          margin: "0.5rem 0",
          backgroundColor: "#393837",
          color:"white"
        }}
        
      />
    </FormControl>
  );
};

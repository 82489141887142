import { Switch, Route, useRouteMatch } from "react-router-dom";

// Pages.
import ProjectPlanPage from "pages/galleria/master-plane/zone-1/Type-H/plan/ProjectPlanPage";
import ProjectGalleryPage from "pages/galleria/master-plane/zone-1/Type-H/gallery/ProjectGalleryPage";
import PaymentPage from "pages/galleria/master-plane/zone-1/Type-H/payment/PaymentPage";
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
    <Route exact path={`${path}/master-plan/zone-1/type-h`}></Route>
    <Route exact path={`${path}/master-plan/zone-1/type-h/plan`}>
      <ProjectPlanPage />
    </Route>
    <Route exact path={`${path}/master-plan/zone-1/type-h/gallery`}>
      <ProjectGalleryPage />
    </Route>
    <Route exact path={`${path}/master-plan/zone-1/type-h/payment`}>
      <PaymentPage />
    </Route>
  </Switch>
  );
};


export default Index;

import { Route } from 'react-router-dom';

import TypeC from './Type-C';
import TypeF from './Type-F';
import TypeG from './Type-G';

// Pages.
import ZonePage from 'pages/galleria/master-plane/zone-3/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-3`}>
        <ZonePage />
      </Route>
      <TypeC />
      <TypeF />
      <TypeG />
    </>
  );
};

export default Index;

import React from 'react';

import LocationIndicator from 'components/sub-components/LocationIndicator/LocationIndicator';
import PageContainerComponent from '../../../../../../components/common/page-container-component/PageContainerComponent';
import styles from './PaymentPage.module.css';
import FloorPlateNavOptions from 'components/common/floor-plate-nav-options/FloorPlateNavOptions';
import { Divider } from '@chakra-ui/react';
function PaymentPage() {
  const labels_Values = [
    { label: 'Type', value: 'Garden' },
    { label: 'View', value: 'Street' },
    { label: 'Area', value: '130' },
    { label: 'Garden', value: '23' },
    { label: 'Total Area', value: '153' },
    { label: 'Cash Price + Parking + Storage', value: '2,441,465' },
    { label: 'Maintenance', value: '349,129' },
    { label: 'Club House', value: '125,000' },
    { label: 'Installment 7 years', value: '3,491,294' },
    { label: 'Installment 15% Deposit', value: '523,694' },
    { label: 'Installment 7 years', value: '423,943' },
    { label: 'Installment Parking', value: '150,000' },
    { label: 'Installment Warehouse', value: '86,000' },
    { label: 'Status', value: 'Available' },
    { label: 'Finishing', value: 'Core & Shell' },
    { label: 'Delivery', value: 'Ready To Go' },
  ];
  return (
    <PageContainerComponent>
      <LocationIndicator text="Zone 5 - Type E" fontSize={60} />
      <div className={styles.paymentContainer}>
        {labels_Values.map((item, index) => (
          <>
            <div className={styles.paymentColumn} key={index}>
              <div className={styles.paymentColumnLabel}>{item.label}</div>
              <div className={styles.paymentColumnValue}>{item.value}</div>
            </div>
            <Divider color="brown" />
          </>
        ))}
      </div>
      <FloorPlateNavOptions isPaymentPage />
    </PageContainerComponent>
  );
}

export default PaymentPage;

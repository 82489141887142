import { Box } from "@chakra-ui/react";
import { useRouteMatch } from "react-router-dom";

// Custom Components.
import OptionsRow from "./OptionsRow";
import BuildingOption from "./BuildingOption";
import OptionsContainer from "./OptionsContainer";

// Images.
import PropertyType1 from "img/galleria/master-plan/zone-3/Type C main cover.png";
import PropertyType2 from "img/galleria/master-plan/zone-3/Type F main cover.png";
import PropertyType3 from "img/galleria/master-plan/zone-3/Type G main cover.png";

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-c"
          planUrl={`${path}/type-c/plan`}
          buildingName="Type C"
          image={PropertyType1}
        />
        <BuildingOption
          typeName="type-f"
          planUrl={`${path}/type-f/plan`}
          buildingName="Type F"
          image={PropertyType2}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="type-g"
          planUrl={`${path}/type-g/plan`}
          buildingName="Type G"
          image={PropertyType3}
        />
        <Box flex={1} h="100%" />
      </OptionsRow>
    </OptionsContainer>
  );
}

import { Button, Center, Image } from "@chakra-ui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

// Icon.
import viewIcon from "img/galleria/common/view-icon.png";
import viewIconActive from "img/galleria/common/view-icon-active.png";

export interface ViewButtonProps {
  planUrl: string;
}

export const ViewButton = (props: ViewButtonProps) => {
  const [currentViewIcon, setCurrentViewIcon] = useState(viewIcon);

  const history = useHistory();

  const onClick = () => {
    history.push(props.planUrl);
  };

  const onMouseEnter = () => {
    setCurrentViewIcon(viewIconActive);
  };

  const onMouseLeave = () => {
    setCurrentViewIcon(viewIcon);
  };

  return (
    <Button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      w="6rem"
      h="full"
      borderBottomRightRadius="1.8rem"
      bgColor="transparent"
      borderColor="white"
      borderWidth="1px"
      _focus={{
        outline: "none",
      }}
    >
      <Center w="full" h="full" py="1.1rem">
        <Image
          w="full"
          h="full"
          objectFit="fill"
          src={currentViewIcon}
          alt="360 button"
        />
      </Center>
    </Button>
  );
};

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Image } from "@chakra-ui/react";
import { Box, Flex } from "@chakra-ui/layout";

import NewCairoImg from "img/galleria/facilities/facilities-img.png";

export default function CtxImage() {
  const image = NewCairoImg;

  const NewCairoContainerStyles = {
    width: {
      base: "100%",
    },
  };

  const ContainerStyles = NewCairoContainerStyles;

  return (
    <Flex
      w="full"
      h="full"
      borderRadius={{ base: "1rem", tablet16: "3rem" }}
      alignItems="center"
      justifyContent="center"
    >
      <Box pos="relative" w={ContainerStyles.width} h="full">
        <Image
          as={LazyLoadImage}
          borderRadius={{ base: "1rem", mobile8: "2rem" }}
          width="100%"
          height="100%"
          src={image}
        />
      </Box>
    </Flex>
  );
}

import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

// Images.
import PropertyTypeG from 'img/galleria/master-plan/zone-5/Type G main cover.png';
import PropertyTypeD from 'img/galleria/master-plan/zone-5/Type D main cover.png';
import PropertyTypeE from 'img/galleria/master-plan/zone-5/Type E main cover.png';
import PropertyTypeH from 'img/galleria/master-plan/zone-5/Type H main cover.png';

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-d"
          planUrl={`${path}/type-d/plan`}
          buildingName="Type D"
          image={PropertyTypeD}
        />
        <BuildingOption
          typeName="type-g"
          planUrl={`${path}/type-g/plan`}
          buildingName="Type G"
          image={PropertyTypeG}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="type-e"
          planUrl={`${path}/type-e/plan`}
          buildingName="Type E"
          image={PropertyTypeE}
        />
        <BuildingOption
          typeName="type-h"
          planUrl={`${path}/type-h/plan`}
          buildingName="Type H"
          image={PropertyTypeH}
        />
      </OptionsRow>
    </OptionsContainer>
  );
}

import { Box, Image, Skeleton } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import { MasterPlanImageContext } from '../ProjectZonePage';

import ZoneImg from 'img/sunCapital/master-plan/cyra/cyra-master-plan.jpeg'; ///main
import ZoneType2 from 'img/sunCapital/master-plan/cyra/TYPE 2/Type 2.jpg';
import ZoneType6 from 'img/sunCapital/master-plan/cyra/Type 6/Type 6.jpg';
import ZoneType7 from 'img/sunCapital/master-plan/cyra/TYPE 7/Type 7.jpg';
import ZoneType8 from 'img/sunCapital/master-plan/cyra/TYPE 8/type 8.jpg';
import ZoneType9 from 'img/sunCapital/master-plan/cyra/TYPE 9/Type 9.jpg';
import ZoneType10 from 'img/sunCapital/master-plan/cyra/TYPE 10/Type 10.jpg';
import ZoneType14 from 'img/sunCapital/master-plan/cyra/TYPE 14/Type 14.jpg';
import ZoneType16 from 'img/sunCapital/master-plan/cyra/TYPE 16/Type 16.jpg';
import ZoneType18 from 'img/sunCapital/master-plan/cyra/TYPE 18/Type 18.jpg';

export default function Img() {
  const [isLoaded, setIsLoaded] = useState(false);

  const { typeName } = useContext(MasterPlanImageContext);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
          objectFit="cover"
          src={selectImage(typeName)}
        />
      </Box>
    </Skeleton>
  );
}

function selectImage(zoneType: string) {
  switch (zoneType) {
    case 'type-2':
      return ZoneType2;
    case 'type-6':
      return ZoneType6;
    case 'type-7':
      return ZoneType7;
    case 'type-8':
      return ZoneType8;
    case 'type-9':
      return ZoneType9;
    case 'type-10':
      return ZoneType10;
    case 'type-14':
      return ZoneType14;
    case 'type-16':
      return ZoneType16;
    case 'type-18':
      return ZoneType18;
    default:
      return ZoneImg;
  }
}

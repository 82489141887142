import { Icon } from "@chakra-ui/react";
import { IconsNames } from "../../pins/utils";

import SingleOptionButton from "../single-option-button/SingleOptionButton";

const Shopping = () => {
  return (
    <SingleOptionButton optionName={IconsNames.Retail}>
      <Icon viewBox="0 0 24 24" fontSize="2rem">
        <defs>
          <clipPath id="a">
            <rect
              width="24"
              height="24"
              transform="translate(141 1658)"
              fill="#ccb288"
            />
          </clipPath>
        </defs>
        <g transform="translate(-141 -1658)" clip-path="url(#a)">
          <g transform="translate(141 1659.453)">
            <path
              d="M7.781,14.063h12.7a.7.7,0,0,0,.676-.51l2.813-9.844a.7.7,0,0,0-.676-.9H6.158L5.655.551A.7.7,0,0,0,4.969,0H.7a.7.7,0,1,0,0,1.406H4.4L6.944,12.832a2.108,2.108,0,0,0,.838,4.043h12.7a.7.7,0,1,0,0-1.406H7.781a.7.7,0,1,1,0-1.406Zm0,0"
              fill="#ccb288"
            />
            <path
              d="M7.078,18.984a2.109,2.109,0,1,0,2.11-2.109A2.112,2.112,0,0,0,7.078,18.984Zm0,0"
              fill="#ccb288"
            />
            <path
              d="M16.969,18.984a2.109,2.109,0,1,0,2.109-2.109A2.112,2.112,0,0,0,16.969,18.984Zm0,0"
              fill="#ccb288"
            />
          </g>
        </g>
      </Icon>
    </SingleOptionButton>
  );
};

export default Shopping;

import { Route } from "react-router-dom";

import TownHouse from "./town-house";
import TwinHouse from "./twin-house";
import StandAlone from "./stand-alone";

// Pages.
import ZonePage from "pages/sun_capital/master-plane/Aurora/ProjectZonePage";

const Index = () => {
  return (
    <>
      <Route exact path="/sun_capital/master-plan/aurora">
        <ZonePage />
      </Route>
      <StandAlone />
      <TownHouse />
      <TwinHouse />
    </>
  );
};

export default Index;

import { Center, Image } from "@chakra-ui/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Redux.
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { isGalleriaImgActive } from "app/master-paln-img/master-plan-img.slice";

// Stylings
import externalStyles from "./ZoneImage.module.css";

// Images
import idleZoneImage from "img/Galleria zones/Mastr-Plan-Galleria.jpg";
import activeZoneImage from "img/galleria/master-plan/Galleria-Master-Plan-Active.png";

// Utils
export default function ZoneImage() {
  const isGalleriaActive = useSelector((state: RootState) =>
    isGalleriaImgActive(state)
  );

  return (
    <Center w="full" h="full">
      <Image
        className={externalStyles.zone_image}
        as={LazyLoadImage}
        borderRadius={{
          base: "1.5rem",
          mobile8: "2.5rem",
          mobile13: "3rem",
          tablet13: "4rem",
          tablet16: "3rem",
        }}
        w="full"
        h="full"
        src={isGalleriaActive ? activeZoneImage : idleZoneImage}
      />
    </Center>
  );
}

import { HStack, Button } from "@chakra-ui/react";
import { useContext } from "react";
import { FacilityPageContext } from "../ProjectFacilitiesPage";

type OptionBtnProps = {
  active: () => any;
  btnName: string | number;
};

export default function OptionBtn(props: OptionBtnProps) {
  const {setActiveIconName} = useContext(FacilityPageContext);
  
  return (
    <HStack
      w={{ base: "32%", tablet16: "full" }}
      h="full"
      spacing="2px"
      bg="white"
      justifyContent="center"
      borderColor="#CCB288"
      borderWidth={{ base: "1px", miniMobile10: "2px", tablet16: "medium" }}
      borderRadius={{ base: "0.4rem", mobile8: "0.8rem", tablet16: "1.5rem" }}
      py={{
        base: "0.5rem",
        tablet16: "2rem",
        desktop5: "1rem",
      }}
      px={{
        base: "0.3rem",
        tablet16: "1rem",
        desktop5: "1rem",
      }}
      role="group"
      _hover={{
        bg: "#CCB288",
        color: "white",
      }}
      _focus={{
        bg: "#CCB288",
        color: "white",
      }}
      _active={{
        bg: "#CCB288",
        color: "white",
      }}
      onClick={() => setActiveIconName(props.btnName.toString().toLowerCase().replace(/ /g, '_'))} 
      color="#CCB288"
    >
      <Button
        _groupHover={{
          color: "white",
          outline: "none",
          bg: "transparent",
        }}
        _groupFocus={{
          outline: "none",
          color: "white",
        }}
        _groupActive={{
          outline: "none",
          color: "white",
        }}
        _focus={{}}
        w="200px"
        fontSize={{
          base: "0.5rem",
          miniMobile10: "0.6rem",
          mobile1: "0.7rem",
          mobile8: "0.8rem",
          mobile13: "1rem",
          tablet2: "1.3rem",
          tablet7: "1.5rem",
          tablet16: "1.5rem",
        }}
        bg="transparent"
        onClick={() => setActiveIconName(props.btnName.toString().toLowerCase().replace(/ /g, '_'))} 
        padding="10px"
      >
        
        {props.btnName}
      </Button>
    </HStack>
  );
}

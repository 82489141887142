import { memo } from "react";

// Custom Components
import VideoComp from "./VideoComp";
import PageInfoInBoxes from "./page-info-in-boxs/PageInfoInBoxes";

// Utils
import { useGetStackTypeElOnPageOrientation } from "utils/Values";
import { useChoseStyleOnLandscape } from "utils/Selecting";

// Images.
function PageInfo() {
  const StackType = useGetStackTypeElOnPageOrientation();

  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
      "2xl": "100%",
    },
    spacing: {
      "1xsm": "0.8rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <StackType
      w="100%"
      h={{ base: "80%" }}
      spacing={{
        base: "0.5rem",
        sm: "1rem",
        lg: "2rem",
      }}
      {...styles}
    >
      <VideoComp />
      <PageInfoInBoxes />
    </StackType>
  );
}

export default memo(PageInfo);

import { Route } from "react-router-dom";

// Pages.
import ProjectPlanPage from "pages/galleria/master-plane/zone-1/Type-C/plan/ProjectPlanPage";
import ProjectGalleryPage from "pages/galleria/master-plane/zone-1/Type-C/gallery/ProjectGalleryPage";
import PaymentPage from "pages/galleria/master-plane/zone-1/Type-C/payment/PaymentPage";

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-1/type-c/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-1/type-c/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-1/type-c/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

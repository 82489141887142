import { Route } from "react-router-dom";

import TypeH from "./mixed_use/index";
import TypeC from "./fairmont_hotel/index";
import TypeF from "./international_schools/index";
import TypeE from "./branded_admin_building/index";
import TypeD from "./Holiday_Inn_and_staybridge/index";

// Pages.
import ZonePage from "pages/sun_capital/master-plane/Capital Heart/ProjectZonePage";

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/capital-heart`}>
        <ZonePage />
      </Route>
      
    </>
  );
};

export default Index;

import { Center, Spinner } from "@chakra-ui/react";

export const LazyLoadingComp = () => {
  return (
    <Center w="full" h="full">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="#CCB288"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
};

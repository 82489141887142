import { Icon } from "@chakra-ui/react";
import { IconsNames } from "../../pins/utils";

import SingleOptionButton from "../single-option-button/SingleOptionButton";

const Education = () => {
  return (
    <SingleOptionButton optionName={IconsNames.Education}>
      <Icon viewBox="0 0 24 24" fontSize="2rem">
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_5122"
              data-name="Rectangle 5122"
              width="24"
              height="24"
              transform="translate(430 1658)"
              fill="#ccb288"
            />
          </clipPath>
        </defs>
        <g
          id="Education-Icon"
          transform="translate(-430 -1658)"
          clip-path="url(#clip-path)"
        >
          <g id="mortarboard" transform="translate(430 1658)">
            <path
              id="Path_12439"
              data-name="Path 12439"
              d="M22.494,15.931V8.466l-1.406.469v7a2.213,2.213,0,0,0-1.506,2.094v3.766a.7.7,0,0,0,.7.7H23.3a.7.7,0,0,0,.7-.7V18.025A2.213,2.213,0,0,0,22.494,15.931Z"
              fill="#ccb288"
            />
            <path
              id="Path_12440"
              data-name="Path 12440"
              d="M11.333,11.742,4.519,9.47v4.789a.7.7,0,0,0,.206.5,10.289,10.289,0,0,0,14.551,0,.7.7,0,0,0,.206-.5V9.47l-6.814,2.271a2.11,2.11,0,0,1-1.334,0Z"
              fill="#ccb288"
            />
            <path
              id="Path_12441"
              data-name="Path 12441"
              d="M12,10.444a.7.7,0,0,0,.222-.036l11.3-3.766a.7.7,0,0,0,0-1.334l-11.3-3.766a.7.7,0,0,0-.445,0L.481,5.308a.7.7,0,0,0,0,1.334l11.3,3.766a.7.7,0,0,0,.222.036Z"
              fill="#ccb288"
            />
          </g>
        </g>
      </Icon>
    </SingleOptionButton>
  );
};

export default Education;

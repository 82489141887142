import { memo } from "react";

// Icons Sources
import InfoIconRed from "img/icons/projects-pages/Info-icon.svg";
import LocationIconRed from "img/icons/projects-pages/Marker.svg";
import GalleryIconRed from "img/icons/projects-pages/Gallery-Icon.svg";
import InfoIconWhite from "img/icons/projects-pages/Info-White-Icon.svg";
import FacilitiesIconRed from "img/icons/projects-pages/Cluster-Icon.svg";
import MasterPlanIconRed from "img/icons/projects-pages/Map-Design-Icon.svg";
import FloorPlansIconRed from "img/icons/projects-pages/Map-Design-Icon.svg";
import GalleryIconWhite from "img/icons/projects-pages/Gallery-White-Icon.svg";
import FacilitiesIconWhite from "img/icons/projects-pages/Cluster-Icon-White.svg";
import LocationIconWhite from "img/icons/projects-pages/Marker-Icon-Bg-White.svg";
import MasterPlanIconWhite from "img/icons/projects-pages/Map-Design-White-Icon.svg";
import FloorPlansIconWhite from "img/icons/projects-pages/Map-Design-White-Icon.svg";

// Custom Components
import Container from "./Container";
import OptionRow from "./OptionRow";
import OptionBtn from "./OptionBtn/OptionBtn";

type Props = {
  isInfoPage?: boolean;
  isLocationPage?: boolean;
  isGalleryPage?: boolean;
  isFacilitiesPage?: boolean;
  isMasterPlanPage?: boolean;
  isFloorsPlanPage?: boolean;

  height?: string;
};

function OptionsButtons(props: Props) {
  return (
    <Container height={props.height}>
      <OptionRow>
        <OptionBtn
          urlPath="presentation"
          image={InfoIconRed}
          imageWhite={InfoIconWhite}
          isSelected={props.isInfoPage}
          name="Info"
        />
        <OptionBtn
          urlPath="location"
          image={LocationIconRed}
          imageWhite={LocationIconWhite}
          isSelected={props.isLocationPage}
          name="Location"
        />
        <OptionBtn
          urlPath="facilities"
          image={FacilitiesIconRed}
          imageWhite={FacilitiesIconWhite}
          isSelected={props.isFacilitiesPage}
          name="Facilities"
        />
      </OptionRow>
      <OptionRow>
        <OptionBtn
          urlPath="gallery"
          image={GalleryIconRed}
          imageWhite={GalleryIconWhite}
          isSelected={props.isGalleryPage}
          name="Gallery"
        />
        <OptionBtn
          urlPath="master-plan"
          image={MasterPlanIconRed}
          imageWhite={MasterPlanIconWhite}
          isSelected={props.isMasterPlanPage}
          name="Master Plan"
        />
        <OptionBtn
          urlPath="floors-plan"
          image={FloorPlansIconRed}
          imageWhite={FloorPlansIconWhite}
          isSelected={props.isFloorsPlanPage}
          name="Floor Plans"
        />
      </OptionRow>
    </Container>
  );
}

export default memo(OptionsButtons);

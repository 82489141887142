import { HStack } from "@chakra-ui/react";

import Container from "./Container";
import OptionBtn from "../SingleOption";

type OptionsButtonsProps = {};

export default function OptionsButtons(props: OptionsButtonsProps) {
  function ActiveOnlySwimmingPoolPin() {}

  function ActiveOnlyFootballPin() {}

  // function ActiveOnlyKidsAreaPin() {}

  function ActiveOnlyMedicalPin() {}

  // function ActiveOnlyRetailPin() {}

  // function ActiveOnlyMosquePin() {}

  function ActiveOnlyTennisPin() {}

  function ActiveOnlyClubPin() {}

  // function ActiveOnlyGYMPin() {}

  const containerStyles = {
    spacing: {
      base: "0.3rem",
      miniMobile10: "0.5rem",
      mobile13: "0.7rem",
      tablet16: "1rem",
    },
  };

  return (
    <Container>
      <HStack
        w="100%"
        h={{ sm: "40%", tablet16: "30%" }}
        spacing={containerStyles.spacing}
      >
        <OptionBtn btnName="Swimming Pools" active={ActiveOnlyClubPin} />
        <OptionBtn btnName="Football Field" active={ActiveOnlyTennisPin} />
        <OptionBtn btnName="Tennis Court" active={ActiveOnlyFootballPin} />
      </HStack>
      <HStack
        w="100%"
        h={{ sm: "40%", tablet16: "30%" }}
        spacing={containerStyles.spacing}
      >
        <OptionBtn btnName="Club House" active={ActiveOnlySwimmingPoolPin} />
        <OptionBtn btnName="Commercial Area" active={ActiveOnlyMedicalPin} />
        {/* <HStack w="10rem" h="full"></HStack> */}
      </HStack>
    </Container>
  );
}

import { Box } from "@chakra-ui/layout";

import { IconsNames } from "./utils";

export interface PinProps {
  lat: number;
  lng: number;
  iconName: IconsNames;
  children: JSX.Element | JSX.Element[];
}

export default function Pin(props: PinProps) {
  return (
    <Box
      w={[
        "20px",
        "20px",
        "12px",
        "20px",
        "20px",
        null,
        null,
        null,
        null,
        "30px",
      ]}
      h={[
        "20px",
        "20px",
        "12px",
        "20px",
        "20px",
        null,
        null,
        null,
        null,
        "30px",
      ]}
      lat={props.lat}
      lng={props.lng}
    >
      {props.children}
    </Box>
  );
}

import { Route, useRouteMatch } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/galleria/master-plane/zone-5/Type-D/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/galleria/master-plane/zone-5/Type-D/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/galleria/master-plane/zone-5/Type-D/payment/PaymentPage';
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-5/type-d/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-5/type-d/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-5/type-d/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

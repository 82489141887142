// Custom Components
import OptionsButtons from "../presentation-page/OptionsButtons";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "hooks/navigation/useVerifyAuth";

// Utils

// Lazy
import { lazy, memo, Suspense } from "react";
import LazyLoadingComp from "components/common/LazyLoadingComp";
import { VStack } from "@chakra-ui/react";

// Lazy Component
const ProjectGallery = lazy(() => import("./ProjectGallery/ProjectGallery"));

function ProjectGalleryPage() {
  // Auth before accessing this page
  useVerifyAuth();

  return (
    <PageContainerComponent overflow="hidden">
      <LocationIndicator text="Sun Capital" />
      <VStack
        w="full"
        h="87%"
        spacing={{ base: "0.5rem", miniMobile10: "0.7rem", tablet16: "2rem" }}
      >
        <Suspense fallback={<LazyLoadingComp />}>
          <ProjectGallery />
        </Suspense>
        <OptionsButtons isGalleryPage />
      </VStack>
    </PageContainerComponent>
  );
}

export default memo(ProjectGalleryPage);

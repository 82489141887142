import { Route } from 'react-router-dom';

import TypeC from './Type-C';
import TypeE from './Type-E';
import TypeF from './Type-F';
import TypeG from './Type-G';
import TypeH from './Type-H';

// Pages.
import ZonePage from 'pages/galleria/master-plane/zone-4/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-4`}>
        <ZonePage />
      </Route>
      <TypeC />
      <TypeE />
      <TypeF />
      <TypeG />
      <TypeH />
    </>
  );
};

export default Index;

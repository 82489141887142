import { Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useChoseOnFlag } from "../../../../utils/Selecting";

type Props = {
  imageIcon: IconType;
  isActive?: boolean;
  isDisabled?: boolean;

};

export default function OptionIcon(props: Props) {
  const activeColor = useChoseOnFlag(props.isActive, "#CCB288", "#CCB288");

  return (
    <Icon
      color="#CCB288"
      sx={{
        "& path": {
          stroke: activeColor,
        },
      }}
      as={props.imageIcon}
      h="100%"
      fontSize={["3.5vw"]}
      opacity= {props.isDisabled ? "0.5":"1"}
      display={props.isDisabled ? "none" : "block"}
    />
  );
}

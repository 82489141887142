import { Route } from "react-router-dom";

import Type5 from "./type-5/index";
import Type15 from "./type-15/index";

// Pages.
import ZonePage from "pages/sun_capital/master-plane/Elio/ProjectZonePage";

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/elio`}>
        <ZonePage />
      </Route>
      <Type5 />
      <Type15 />
    </>
  );
};

export default Index;

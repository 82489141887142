import { VStack } from "@chakra-ui/react";

type Props = {
  children: JSX.Element | JSX.Element[];
  height?: string;
};

function Container(props: Props) {
  return (
    <VStack
      w={{
        base: "97%",
        mobile9: "97%",
        mobile13: "100%",
        tablet12: "100%",
        desktop5: "30%",
      }}
      h={
        props.height || {
          base: "17%",
          miniMobile8: "17%",
          mobile9: "17%",
          mobile16: "17%",
          tablet12: "17%",
          desktop5: "100%",
        }
      }
      spacing={{
        base: "7.5rem",
        mobile9: "0.6rem",
        tablet12: "1rem",
        desktop5: "1.5rem",
      }}
      pb={{
        mobile9: "1rem",
        desktop5: "1rem",
      }}
      justifyContent="space-between"
      backgroundColor="#393837"
    >
      {props.children}
    </VStack>
  );
}

export default Container;

import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

import { Location } from "components/project-facilities-page/hero-image/project-pins/utils";

type Props = {
  location: Location;
  zoneNumber: number;
  disable?: boolean;
};

export default function Zone(props: Props) {
  const { path } = useRouteMatch();

  const destinationUrl = `${path}/zone-${props.zoneNumber}`;

  return (
    <Button
      as={props.disable ? undefined : Link}
      to={destinationUrl}
      position="absolute"
      top={props.location.top}
      left={props.location.left}
      fontSize={{
        base: "1rem",
        mobile8: "1.5rem",
        tablet13: "2.5rem",
        tablet16: "3rem",
      }}
      w={{ tablet7: "11rem", tablet16: "15rem" }}
      h={{ tablet7: "4rem", tablet16: "7rem" }}
      bgColor="transparent"
      disabled={props.disable}
      _disabled={{
        bg: "gray.300",
        opacity: 0.5,
      }}
      _hover={{
        bgColor: "transparent",
        cursor: "pointer",
      }}
      _active={{
        bgColor: "transparent",
        cursor: "pointer",
      }}
      _focus={{
        bgColor: "transparent",
        cursor: "pointer",
      }}
    ></Button>
  );
}

import React from 'react';

import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../../../../../../components/common/page-container-component/PageContainerComponent";
import styles from "./PaymentPage.module.css";
import FloorPlateNavOptions from "components/common/floor-plate-nav-options/FloorPlateNavOptions";
import { Box, Divider } from "@chakra-ui/react";
function PaymentPage() {
  const labels_Values = [
    { label: 'Type', value: 'Garden' },
    { label: 'View', value: 'Street' },
    { label: 'Area', value: '139' },
    { label: 'Garden', value: '22' },
    { label: 'Total Area', value: '161' },
    { label: 'Cash Price + Parking + Storage', value: '2,372,575' },
    { label: 'Maintenance', value: '339,278' },
    { label: 'Club House', value: '125,000' },
    { label: 'Installment 7 years', value: '3,392,782' },
    { label: 'Installment 15% Deposit', value: '508,917' },
    { label: 'Installment 7 years', value: '411,981' },
    { label: 'Installment Parking', value: '150,000' },
    { label: 'Installment Warehouse', value: '86,000' },
    { label: 'Status', value: 'Available' },
    { label: 'Finishing', value: 'Core & Shell' },
    { label: 'Delivery', value: 'Ready To Go' },
  ];
  return (
    <PageContainerComponent>
      <LocationIndicator text="Zone 4 - Type E" fontSize={60} />
      <div className={styles.paymentContainer}>
        {labels_Values.map((item, index) => (
          <>
            <Box className={styles.paymentColumn} key={index}>
              <Box
                as="p"
                className={styles.paymentColumnLabel}
                fontSize={{
                  tablet7: "1.6rem",
                  tablet16: "3rem",
                }}
              >
                {item.label}
              </Box>
              <Box
                as="p"
                className={styles.paymentColumnValue}
                fontSize={{
                  tablet7: "1.6rem",
                  tablet16: "3rem",
                }}
              >
                {item.value}
              </Box>
            </Box>
            <Divider color="brown" />
          </>
        ))}
      </div>
      <FloorPlateNavOptions isSold />
    </PageContainerComponent>
  );
}

export default PaymentPage;

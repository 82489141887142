import _ from "lodash";

import Button from "./Btn";
import Container, { ContainerProps } from "./Container";

import { useSelector, useDispatch } from "react-redux";
import { activateIcon } from "app/map/activeCompSlice_sunCapital";
import { deactivateIcon } from "app/map/activeCompSlice_sunCapital";
import { selectActiveIcons } from "app/map/activeCompSlice";
import { IconsNames } from "../../pins/utils";

export interface SingleOptionButtonProps extends ContainerProps {
  children: JSX.Element | JSX.Element[];
  optionName: IconsNames;
}

export default function SingleOptionButton(props: SingleOptionButtonProps) {
  const activeIcons = useSelector(selectActiveIcons);
  const dispatch = useDispatch();

  function toggleActivationForIcon() {
    const isActive = !!_.propertyOf(activeIcons)(props.optionName);
    if (isActive) return dispatch(deactivateIcon(props.optionName));

    return dispatch(activateIcon(props.optionName));
  }

  return (
    <Container
      activateOption={toggleActivationForIcon}
      isResidential={props.isResidential}
    >
      {props.children}
      <Button optionName={props.optionName} />
    </Container>
  );
}

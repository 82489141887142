import { Text } from "@chakra-ui/react";

export default function Txt() {
  return (
    <Text
      w="100%"
      h="fit-content"
      fontSize={{
        base: "1rem",
        miniMobile8: "1.5rem",
        miniMobile12: "1.8rem",
        mobile8: "2.3rem",
        tablet7: "3.5rem",
        tablet16: "3rem",
      }}
      fontWeight="600"
      color="white"
    >
      Master Plan
    </Text>
  );
}

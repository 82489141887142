import { Route, useRouteMatch } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/galleria/master-plane/zone-6/Type-C/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/galleria/master-plane/zone-6/Type-C/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/galleria/master-plane/zone-6/Type-C/payment/PaymentPage';
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-6/type-c/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-6/type-c/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-6/type-c/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

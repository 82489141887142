import { HStack } from "@chakra-ui/react";

import Container from "./Container";
import OptionBtn from "../SingleOption";

export default function OptionsButtons() {
  function ActiveOnlySwimmingPoolPin() {}

  function ActiveOnlyFootballPin() {}

  function ActiveOnlyKidsAreaPin() {}

  function ActiveOnlyMedicalPin() {}

  function ActiveOnlyRetailPin() {}

  function ActiveOnlyMosquePin() {}

  // function ActiveOnlyTennisPin() {}

  function ActiveOnlyClubPin() {}

  function ActiveOnlyGYMPin() {}

  const containerStyles = {
    spacing: {
      base: "0.3rem",
      miniMobile10: "0.5rem",
      mobile13: "0.7rem",
      tablet16: "3rem",
    },
  };

  return (
    <Container>
      <HStack w="100%" h="30%" spacing={containerStyles.spacing}>
        <OptionBtn
          btnName="Swimming Pools"
          active={ActiveOnlySwimmingPoolPin}
        />
        <OptionBtn btnName="School" active={ActiveOnlyClubPin} />
        {/* <OptionBtn btnName="Club House" active={ActiveOnlyTennisPin} /> */}
        <OptionBtn btnName="Retail-Mixed Use" active={ActiveOnlyFootballPin} />
      </HStack>
      <HStack w="100%" h="30%" spacing={containerStyles.spacing}>
        <OptionBtn btnName="Fairmont Hotel" active={ActiveOnlyMedicalPin} />
        <OptionBtn btnName="Holiday Inn Hotel" active={ActiveOnlyMosquePin} />
        <OptionBtn btnName="Stay Bridge" active={ActiveOnlyKidsAreaPin} />
      </HStack>
      <HStack w="100%" h="30%" spacing={containerStyles.spacing}>
        <OptionBtn btnName="Admin Building" active={ActiveOnlyGYMPin} />
        <OptionBtn btnName="Tennis Academy" active={ActiveOnlyRetailPin} />
      </HStack>
    </Container>
  );
}

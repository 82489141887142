import { useRef } from "react";

import { Select } from "@chakra-ui/react";

import InputEl from "./InputEl";

export default function EmailInput() {
  const emailTypeSelectElement = useRef<HTMLSelectElement>(null);

  return (
    <>
      <InputEl selectorRef={emailTypeSelectElement} />
    </>
  );
}

import { Box } from '@chakra-ui/react';

import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

// Images.
import stnadAlone from 'img/sun-capital/Master plan/Hani Saad/Stand alone main cover.jpg';
import townHouse from 'img/sun-capital/Master plan/Hani Saad/Town house main cover.jpg';
import twinHouse from 'img/sun-capital/Master plan/Hani Saad/Twin house main cover.jpg';

export default function AllBuildings() {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="stand-alone"
          planUrl={`${path}/stand-alone/plan`}
          buildingName="Stand Alone"
          image={stnadAlone}
        />
        <BuildingOption
          typeName="townhouse"
          planUrl={`${path}/townhouse/plan`}
          buildingName="Townhouse"
          image={townHouse}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="twin-house"
          planUrl={`${path}/twin-house/plan`}
          buildingName="Twin House"
          image={twinHouse}
        />
        <Box flex={1} h="100%" />
      </OptionsRow>
    </OptionsContainer>
  );
}

import Header from "./Header";
import ErrorMsg from "./ErrorMsg";
import Container from "./Container";
import Disclaimer from "./Disclaimer";
import SendingComp from "./sending-component/SendingComp";

export default function SharingComp() {
  return (
    <Container>
      <Header />
      <SendingComp />
      <Disclaimer />
      <ErrorMsg />
    </Container>
  );
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
} from "@chakra-ui/react";

import { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectClientEmail } from "./sharingSlice";
import { selectDisclaimerVisibility } from "./sharingSlice";
import { hideDisclaimer } from "./sharingSlice";

import axios from "axios";
import baseURL from "constants/baseURL";

function Disclaimer() {
  // Client Email
  const clientEmail = useSelector(selectClientEmail);

  // Url Values.
  const urlValues = window.location.pathname.split("/");
  const projectName = urlValues[1];
  const zone = urlValues[3];
  const type = urlValues[4];

  // Element Display
  const isOpen = useSelector(selectDisclaimerVisibility);

  const dispatch = useDispatch();

  const hideElement = () => {
    if (isOpen) return dispatch(hideDisclaimer());
  };

  const cancelRef = useRef(null);

  // Sending Email With Attachments
  const sendEmail = async () => {
    if (!isOpen) return;
    try {
      await axios
        .post(
          `properties/zone-share?zone=${zone}&type=${type}&project=${projectName}&email=${clientEmail}`,
          {},
          {
            baseURL,
          }
        )
        .then(() => hideDisclaimer());
    } catch (e: any) {
      console.error("Can't Send Email");
    }

    return dispatch(hideDisclaimer());
  };

  return (
    <AlertDialog
      isOpen={!!isOpen}
      leastDestructiveRef={cancelRef}
      onClose={hideElement}
      isCentered={true}
    >
      <AlertDialogContent>
        <AlertDialogHeader>Send Email</AlertDialogHeader>
        <AlertDialogBody>Do you want to send email ?</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={hideElement}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={sendEmail} ml={3}>
            Send
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default Disclaimer;

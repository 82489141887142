import React, { Suspense } from "react";

import { Box, Image } from "@chakra-ui/react";

import { useChoseStyleOnLandscape } from "./utils/Selecting";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import LazyLoadingComp from "components/common/LazyLoadingComp";

// Images.
import celltekTradeMark from "./img/common/celltek-trade-mark.png";
import arabiaDevelopmentsMiniLogo from "./img/common/arabia-developments-mini-logo.svg";
import SharingComp from "components/common/sharing/SharingComp";

type Props = {
  children?: JSX.Element | JSX.Element[];
};

export default function AppContainer(props: Props) {
  const containerStylesInLandscape = {
    py: {
      "1xsm": "0.7rem",
      xl: "3.5rem",
    },
    px: {
      "1xsm": "0.9rem",
      xl: "2rem",
      
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <>
      <Box
        bg="#393837"
        px={{
          base: "4%",
          tablet12: "6rem",
        }}
        py={{
          base: "4%",
          tablet4: "3%",
          tablet12: "1.5rem",
        }}
        w="100%"
        h="100%"
        position="relative"
        zIndex="0"
        {...styles}
      >
        <Router>
          <Suspense fallback={<LazyLoadingComp />}>
            <Switch>{props.children}</Switch>
          </Suspense>
        </Router>
        <Image
          position="absolute"
          zIndex="10"
          left={{ "4xsm": "8rem", sm: "23rem", tablet16: "25rem" }}
          bottom={{ "4xsm": "1rem", tablet16: "1.5rem" }}
          src={celltekTradeMark}
          alt="Celltek Trade Mark"
          width={{
            "4xsm": "10rem",
            tablet16: "unset",
          }}
        />
        <Image
          position="absolute"
          w="90vw"
          h="55vh"
          zIndex="-20"
          top="25rem"
          left="0"
          src={arabiaDevelopmentsMiniLogo}
          alt="Arabia Developments Mini Logo"
        />
      </Box>

      <SharingComp />
    </>
  );
}

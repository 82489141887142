import Txt from "./Txt";
import Container from "./Container";
import AllBuildings from "../buildings-option/AllBuildings";

type Props = {};

export default function OptionsContainer(props: Props) {
  return (
    <Container>
      <Txt />
      <AllBuildings />
    </Container>
  );
}

import { useState } from "react";

import { useHistory } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";

import axios, { AxiosResponse } from "axios";

import {
  useToast,
  VStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  Image,
} from "@chakra-ui/react";

// Redux and It's Actions

// Constants
import baseURL from "constants/baseURL";

// Interfaces
import Admin from "interfaces/Admin";
import LoginCredentials from "interfaces/LoginCredentials";
import ServerResponseError from "interfaces/ServerResponseError";

// Images.
import loginPageBackgroundImg from "img/login-page/login-page-background-img.png";
import arabiaDevelopmentLogo from "img/login-page/arabia-developments-logo.png";

export interface LoginPageProps {
  destinationToUrl?: string;
}

export const LoginPage = (props: LoginPageProps) => {
  const { register, handleSubmit } = useForm<LoginCredentials>();

  const toast = useToast({
    duration: 3000,
    position: "top",
    isClosable: true,
    status: "success",
  });

  const history = useHistory();

  const onSubmit: SubmitHandler<LoginCredentials> = async (data) => {
    setIsLoading(true);

    try {
      const response: AxiosResponse<Admin, ServerResponseError> =
        await axios.post(`${baseURL}/auth/login/real-estate`, data, {
          withCredentials: true,
        });
      setIsLoading(false);

      toast({
        title: "Login Successful",
      });

      if (response.data.username) {
        localStorage.setItem("admin_username", response.data.username);
      }

      if (response.data.adminType) {
        localStorage.setItem("admin_type", response.data.adminType);
      }

      navigateToPage(history, props.destinationToUrl);
    } catch (error: any) {
      setIsLoading(false);

      toast({
        status: "error",
        title: "Couldn't login",
        description: displayErrorMsg(error),
      });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <VStack pos="absolute" top="0" left="0" zIndex="10" w="100vw" h="100vh">
      {/** The background Image */}
      <Image
        src={loginPageBackgroundImg}
        alt="background image"
        pos="absolute"
        inset="0"
        width="full"
        height="full"
        objectFit="cover"
        zIndex="-10"
      />

      <VStack
        width="full"
        height="full"
        alignItems="center"
        pt={{ "4xsm": "10rem", sm: "25rem", tablet16: "20rem" }}
        justifyContent="flex-start"
        spacing="3rem"
      >
        <Image src={arabiaDevelopmentLogo} alt="Arabia Development Logo" />
        <form
          style={{
            width: "60%",
          }}
          method="POST"
          action={`${baseURL}/auth/login`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <VStack
            w="full"
            h="full"
            p={{ md: "1rem", "2xl": "1.5rem" }}
            spacing={{
              md: "0.8rem",
              "2xl": "1.5rem",
              sm: "2rem",
              tablet16: "2rem",
            }}
            borderRadius="1rem"
          >
            <FormControl flex="1">
              <FormLabel
                mb="1rem"
                fontSize="1.5rem"
                color="white"
                fontFamily="Poppins"
              >
                Username
              </FormLabel>
              <Input
                {...register("username")}
                id="username"
                h="3rem"
                placeholder="Write your username"
                style={{
                  background: "#FFFFFF33 0% 0% no-repeat padding-box",
                  color: "white",
                }}
                _focus={{
                  borderColor: "white",
                  background: "#FFFFFF33 0% 0% no-repeat padding-box",
                }}
              />
            </FormControl>
            <FormControl flex="1">
              <FormLabel
                mb="1rem"
                fontSize="1.5rem"
                color="white"
                fontFamily="Poppins"
              >
                Password
              </FormLabel>
              <Input
                {...register("password")}
                id="password"
                type="password"
                h="3rem"
                placeholder="Write your password"
                style={{
                  background: "#FFFFFF33 0% 0% no-repeat padding-box",
                  color: "white",
                }}
                _focus={{
                  borderColor: "white",
                }}
              />
            </FormControl>
            <Button
              bgColor="#CCB288"
              color="white"
              _hover={{
                bgColor: "#CCB281",
              }}
              type="submit"
              isLoading={isLoading}
              className="bg-blue-900"
              flex="2"
              w={400}
              height={100}
              fontSize="1.5rem"
              style={{ padding: "1rem", fontFamily: "Poppins" }}
            >
              Login
            </Button>
          </VStack>
        </form>
      </VStack>
    </VStack>
  );
};

function navigateToPage(history: any, destinationToUrl?: string) {
  if (destinationToUrl) {
    history.push(destinationToUrl);
  } else {
    history.goBack();
  }
}

function displayErrorMsg(error: any) {
  const StatusCode = error?.response?.data?.statusCode;

  let errMsg = "";

  switch (StatusCode) {
    case 401:
      errMsg = "Invalid Credentials";
      break;
    case 500:
      errMsg = "Internal Server Error";
      break;
    case 403:
      errMsg = "Access Denied";
      break;
    default:
      errMsg = "Unknown Error";
      break;
  }

  return errMsg;
}

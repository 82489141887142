import { HStack } from "@chakra-ui/react";

export default function OptionsRow(props: any) {
  return (
    <HStack
      w="100%"
      h="23rem"
      spacing={{
        base: "2vw",
        tablet16: "2vw",
      }}
    >
      {props.children}
    </HStack>
  );
}

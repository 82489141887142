import React, { useState } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";

export interface VideoJSProps {
  options: VideoJsPlayerOptions;
  onReady: (player: VideoJsPlayer) => void;
}

export const VideoJS = (props: any) => {
  const { options, onReady } = props;

  const videoRef = React.useRef(null);

  const [player, setPlayer] = useState<VideoJsPlayer>();

  React.useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      const videoPlayer = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(videoPlayer);
      });

      setPlayer(videoPlayer);
    }
  }, [onReady, options, player, videoRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
};

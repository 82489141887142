import { Route } from "react-router-dom";

import TypeC from "./108_building/index";
import TypeD from "./109_building/index";

// Pages.
import ZonePage from "pages/sun_capital/master-plane/Horizontal/ProjectZonePage";

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/horizontal`}>
        <ZonePage />
      </Route>
      <TypeC />
      <TypeD />
    </>
  );
};

export default Index;

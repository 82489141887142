import { Route } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/sun_capital/master-plane/Horizontal/108 Building/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/sun_capital/master-plane/Horizontal/108 Building/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/sun_capital/master-plane/Horizontal/108 Building/payment/PaymentPage';

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/horizontal/108-building/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/horizontal/108-building/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/horizontal/108-building/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

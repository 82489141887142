import { IconType } from "react-icons";

import OptionContainer from "./option-container/OptionContainer";
import OptionIcon from "./OptionIcon";
import OptionBtn from "./OptionBtn";

type Props = {
  imageIcon: IconType;
  buttonName: string;
  destinationUrl: string;
  isActive?: boolean;
  clickAction?: (x?: any) => any;
  isLinkComp: boolean;
  id?: string | null;
  bedroomsNumber?: string | number;
  isSold?: boolean;
};

export interface NavButtonOptionProps extends Props {}

export default function NavButtonOption(props: NavButtonOptionProps) {
  return (
    <OptionContainer
      destinationUrl={props.destinationUrl}
      isLinkComp={props.isLinkComp}
      clickAction={props.clickAction}
      isActive={props.isActive}
      bedroomsNumber={props.bedroomsNumber}
      isDisabled={props.isSold}
    >
      <OptionIcon imageIcon={props.imageIcon} isActive={props.isActive}  isDisabled={props.isSold}/>
      <OptionBtn
        isActive={props.isActive}
        buttonName={props.buttonName}
        isDisabled={props.isSold}
      />
    </OptionContainer>
  );
}

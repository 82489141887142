import {Text} from "@chakra-ui/react";

import {useGetQueryValue} from "utils/Values";

export default function Txt() {
    const zoneNumber = useGetQueryValue("z") || "1";
    const isZoneNumber2 = zoneNumber === "2";

    return (
        <Text
            w="100%"
            fontSize={{
                base: '1rem',
                miniMobile8: "1.2rem",
                miniMobile12: "1.5rem",
                mobile8: "2rem",
                tablet7: "3rem",
                tablet16: "3rem"
            }}
            fontWeight="600"
            color="white"
        >
            {isZoneNumber2 ? "Villa Unit Types" : "Units Type"}
        </Text>
    );
}

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import authenticationReducer from "./auth/auth.slice";
import activeIconsReducer from "./map/activeCompSlice";
import themeReducer from "../features/theme/themeSlice";
import masterPlanImgReducer from 'app/master-paln-img/master-plan-img.slice';
import sharingSliceReducer from "../components/common/sharing/sharingSlice";
import NavigationMenuReducer from "../components/common/NavigationMenu/NavigationMenuSlice";
import infoBoxReducer from "../components/project-presentation-page/PageInfo/page-info-in-boxs/InfoBox/InfoBoxSlice";
import propertyReducer from "./property/property.slice";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    activeIcons: activeIconsReducer,
    sharingVisibility: sharingSliceReducer,
    infoBoxAnimation: infoBoxReducer,
    navigationMenuAnimation: NavigationMenuReducer,
    authentication: authenticationReducer,
    property: propertyReducer,
    masterPlanImg : masterPlanImgReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

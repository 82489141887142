import {HStack, VStack} from "@chakra-ui/react";
import {
    useChoseStyleOnLandscape,
    useChoseValueOnLandscape,
} from "utils/Selecting";

type Props = {
    children: JSX.Element | JSX.Element[];
};

function OptionRow(props: Props) {
    const VStackTypeOnLandscape = useChoseValueOnLandscape(VStack, HStack);

    // Landscape Styling.
    const containerStylesInLandscape = {
        h: {
            base: "100%",
            tablet13: "50%",
        },
        w: {
            tablet13: "100%",
        },
        spacing: {
            base: "0.8rem",
            mobile7: "0.4rem",
            mobile9: "0.5rem",
            tablet12: "1.3rem",
            desktop5: "1.5rem",
        },
    };
    const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

    return (
        <VStackTypeOnLandscape
            pos="relative"
            zIndex="3"
            w={{base: "100%", mobile9: "98%", tablet12: "98%", desktop5: "94%"}}
            h={{base: "43%", mobile9: "44%", tablet12: "45%", desktop5: "48%"}}
            spacing={{
                base: "0.8rem",
                miniMobile8: "0.4rem",
                mobile9: "0.5rem",
                tablet12: "2rem",
                desktop5: "1.5rem",
            }}
            justifyContent="space-between"
            flex={1}
            {...styles}
        >
            {props.children}
        </VStackTypeOnLandscape>
    );
}

export default OptionRow;

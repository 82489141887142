import { VStack } from "@chakra-ui/react";

// Styling
import externalStyles from "./ProjectMasterPlanePage.module.css";

// Custom Components
import HeroImage from "./PageHeroImage/PageHeroImage";
import OptionsButtons from "../presentation-page/OptionsButtons";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "hooks/navigation/useVerifyAuth";

export default function ProjectMasterPlanePage() {
  // Auth before accessing this page
  useVerifyAuth();

  return (
    <PageContainerComponent overflow="hidden">
      <LocationIndicator
        pageUrl="galleria/presentation"
        fontSize={{
          base: "1rem",
          miniMobile9: "1.3rem",
          miniMobile12: "1.3rem",
          mobile1: "1.4rem",
          mobile8: "2.0rem",
          mobile13: "2.5rem",
          tablet16: "3.5rem",
        }}
        text="Galleria"
      />
      <VStack
        className={externalStyles.main_content_container}
        w="full"
        h="87%"
        justifyContent="space-between"
        spacing={{ base: "0.7rem", tablet16: "2rem" }}
      >
        <HeroImage />
        <OptionsButtons isMasterPlanPage />
      </VStack>
    </PageContainerComponent>
  );
}

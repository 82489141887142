import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import { Location } from "components/project-facilities-page/hero-image/project-pins/utils";

type Props = {
  location: Location;
  zoneName: string;
  disable?: boolean;
  width?: string;
  height?: string;
};

export default function Zone(props: Props) {

  const destinationUrl = `/sun_capital/master-plan/${props.zoneName}`;

  return (
    <Button
      as={props.disable ? undefined : Link}
      to={destinationUrl}
      position="absolute"
      top={props.location.top}
      left={props.location.left}
      fontSize={{
        base: "1rem",
        mobile8: "1.5rem",
        tablet13: "2.5rem",
        tablet16: "3rem",
      }}
      transform={props.location.rotate}
      w={props.width ? props.width : "13rem"}
      h={ props.height ? props.height : ["10%", null, null, null, null, null, null, null, "14%", "7rem"]}
      bgColor="transparent"
      disabled={props.disable}
      _disabled={{
        bg: "gray.300",
        opacity: 0.5,
      }}
      _hover={{
        bgColor: "transparent",
        cursor: "pointer",
      }}
      _active={{
        bgColor: "transparent",
        cursor: "pointer",
      }}
      _focus={{
        bgColor: "transparent",
        cursor: "pointer",
      }}
    ></Button>
  );
}

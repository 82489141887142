import { IconType } from "react-icons";

import { IoBookSharp, IoSchoolSharp, IoGitPullRequest } from "react-icons/io5";
import { RiBuilding2Fill } from "react-icons/ri";
import { BsCartFill } from "react-icons/bs";
import { ImAidKit } from "react-icons/im";

import RestaurantIcon from "img/icons/location-map/RestaurantIcon";


export const enum IconsNames {
  Retail = "Retail",
  Medical = "Medical",
  Education = "Education",
  Landmarks = "Landmarks",
  Attractions = "Attractions",
  Connectivity = "Connectivity",
}

export function pickIcon(iconName: IconsNames): IconType | any {
  switch (iconName) {
    case IconsNames.Attractions:
      return RiBuilding2Fill;
    case IconsNames.Connectivity:
      return IoSchoolSharp;
    case IconsNames.Education:
      return BsCartFill;
    case IconsNames.Medical:
      return ImAidKit;
    case IconsNames.Retail:
      return IoBookSharp;
    case IconsNames.Landmarks:
      return RestaurantIcon;
    
    default:
      return IoGitPullRequest;
  }
}

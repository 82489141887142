import { Switch, Route, useRouteMatch } from "react-router-dom";

// Pages.
import ProjectPlanPage from "pages/galleria/master-plane/zone-1/Type-E/plan/ProjectPlanPage";
import ProjectGalleryPage from "pages/galleria/master-plane/zone-1/Type-E/gallery/ProjectGalleryPage";
import PaymentPage from "pages/galleria/master-plane/zone-1/Type-E/payment/PaymentPage";
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <>
     
    <Route exact path={`/galleria/master-plan/zone-1/type-e/plan`}>
      <ProjectPlanPage />
    </Route>
    <Route exact path={`/galleria/master-plan/zone-1/type-e/gallery`}>
      <ProjectGalleryPage />
    </Route>
    <Route exact path={`/galleria/master-plan/zone-1/type-e/payment`}>
      <PaymentPage />
    </Route>
  </>
  );
};


export default Index;

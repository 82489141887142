import { memo } from "react";

import Zone from "./Zone";

function Zones() {
  return (
    <>
      <Zone
        location={{
          top: ["3%", "3%", "12%", "12%", "12%", "47.5%"],
          left: "46%",
        }}
        height="3rem"
        width="9rem"
        zoneName="aurora"
        
      />
      <Zone
        location={{
          top: ["3%", "3%", "12%", "12%", "12%", "13%"],
          left: "11%",
          rotate: "rotate(-54deg)",
        }}
        width="25rem"
        height="11rem"
        zoneName="capital-heart"
      />
      <Zone
        location={{
          top: ["30%", "30%", "40%", "40%", "42%", "61%"],
          left: "39%",
        }}
        width="11rem"
        height="6rem"
        zoneName="cyra"
      />
      <Zone
        location={{
          top: ["30%", "30%", "40%", "40%", "42%", "40%"],
          left: "35%",
          rotate: "rotate(-54deg)",
        }}
        width="12rem"
        height="4rem"
        zoneName="horizontal"
      />
      <Zone
        location={{
          top: ["3%", "3%", "12%", "12%", "12%", "77%"],
          left: "41%",
        }}
        width="8rem"
        height="4rem"
        zoneName="elio"
      />
      <Zone
        location={{
          top: ["60%", "60%", "68%", "68%", "68%", "51.3%"],
          left: "26%",
          rotate: "rotate(-15deg)",
        }}
        width="11rem"
        height="4rem"
        zoneName="u-green"
      />
       <Zone
        location={{
          top: ["60%", "60%", "68%", "68%", "68%", "35.3%"],
          left: "53%",
          rotate: "rotate(30deg)",
        }}
        width="14rem"
        height="5rem"
        zoneName="hani-saad"
      />
    </>
  );
}

export default memo(Zones);

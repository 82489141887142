import { Route } from "react-router-dom";

import StandAlone from "./stand-alone";
import TownHouse from "./town-house";
import TwinHouse from "./twin-house";

// Pages.
import ZonePage from "pages/sun_capital/master-plane/Hani Saad/ProjectZonePage";

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/hani-saad`}>
        <ZonePage />
      </Route>
      <StandAlone />
      <TownHouse />
      <TwinHouse />
    </>
  );
};

export default Index;

import { Route } from 'react-router-dom';

import TypeB from './Type-B';
import TypeD from './Type-D';

// Pages.
import ZonePage from 'pages/galleria/master-plane/zone-7/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-7`}>
        <ZonePage />
      </Route>
      <TypeB />
      <TypeD />
    </>
  );
};

export default Index;

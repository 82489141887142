import { useDispatch } from "react-redux";

import { showSharing } from "../sharing/sharingSlice";

import { Button, Center, Icon } from "@chakra-ui/react";

// Icon.
import { BiShare } from "react-icons/bi";

export const ShareBtn = () => {
  const dispatch = useDispatch();


  
  return (
    <Button
      mr="1rem"
      w="8rem"
      h="7rem"
      borderRadius="0.8rem"
      bgColor="transparent"
      borderColor="white"
      borderWidth="0.1rem"
      color="white"
      onClick={() => dispatch(showSharing())}
    >
      <Center w="full" h="full" py="8px">
        <Icon w="full" h="full" as={BiShare} objectFit="fill" />
      </Center>
    </Button>
  );
};

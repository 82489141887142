import { Route } from "react-router-dom";

// Pages.
import MasterPlanPage from "pages/galleria/master-plane/ProjectMasterPlanePage";

import Zone1 from "./zone-1";
import Zone2 from "./zone-2";
import Zone3 from "./zone-3";
import Zone4 from "./zone-4";
import Zone5 from "./zone-5";
import Zone6 from "./zone-6";
import Zone7 from "./zone-7";

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan`}>
        <MasterPlanPage />
      </Route>
      <Zone1 />
      <Zone2 />
      <Zone3 />
      <Zone4 />
      <Zone5 />
      <Zone6 />
      <Zone7 />
    </>
  );
};

export default Index;

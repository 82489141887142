import { memo } from "react";

import { HStack } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  selectIconAnimationState,
  startIconAnimation,
  stopIconAnimation,
} from "../PageInfo/page-info-in-boxs/InfoBox/InfoBoxSlice";

type Props = {
  children: JSX.Element | JSX.Element[];
  isSelected?: boolean;
  urlPath: string;
  isInfoBtn?: boolean;
  activeBtn?: (x: any) => any;
};

function BtnContainer(props: Props) {
  const url = props.urlPath;

  // Start Component Animation
  const isButtonsShown = useSelector(selectIconAnimationState);
  const dispatch = useDispatch();

  function animateInfoBox() {
    if (props.activeBtn) props.activeBtn(true);

    if (isButtonsShown) return dispatch(stopIconAnimation());
    return dispatch(startIconAnimation());
  }

  return (
    <HStack
      as={Link}
      to={url}
      onClick={props.isInfoBtn ? animateInfoBox : undefined}
      w={{
        base: "31%",
        mobile9: "97%",
        tablet2: "31.5%",
        tablet12: "70%",
        desktop5: "94%",
      }}
      h={{ base: "100%", desktop5: "40%" }}
      spacing={{
        base: "2px",
        sm: "1.2rem",
        lg: "2px",
      }}
      bg={props.isSelected ? "#CCB288" : "white"}
      borderColor="#CCB288"
      borderWidth={{ base: "1px", miniMobile10: "1px" }}
      borderRadius={{
        base: "5px",
        sm: "0.8rem",
        lg: "1.5rem",
        miniMobile9: "0.7rem",
        miniMobile10: "0.6rem",
        mobile8: "1rem",
        mobile9: "0.6rem",
      }}
      py={{
        base: "2px",
        sm: "0.5rem",
        lg: "2rem",
        mobile7: "0.4rem",
        desktop5: "1rem",
        tablet10: "0.9rem",
        tablet19: "1rem",
      }}
      px={{
        base: "5px",
        sm: "1.5rem",
        miniMobile9: "0.5rem",
        miniMobile10: "0.5rem",
        mobile8: "1rem",
        tablet12: "1rem",
        desktop5: "1rem",
      }}
      boxShadow={{
        base: props.isSelected ? "6px 6px 6px darkGray" : "6px 6px 6px #CCB288",
        miniMobile8: "2px 3px 2px #CCB288",
        miniMobile10: "3px 3px 2px #CCB288",
      }}
      zIndex="-2"
      role="group"
      _active={{
        bg: "#CCB288",
        color: "white",
      }}
    >
      {props.children}
    </HStack>
  );
}

export default memo(BtnContainer);

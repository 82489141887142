import React from "react";

import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../../../../../../components/common/page-container-component/PageContainerComponent";
import styles from "./PaymentPage.module.css";
import FloorPlateNavOptions from "components/common/floor-plate-nav-options/FloorPlateNavOptions";
import {Box, Divider } from "@chakra-ui/react";
function PaymentPage() {
  const labels_Values = [
    { label: "Building", value: "E" },
    { label: "GROSS AREA", value: "87" },
    { label: "Garden", value: "N/A" },
    { label: "Pent House Area", value: "N/A" },
    { label: "Roof Area", value: "N/A" },
    { label: "Total Apartment", value: "87" },
    { label: "Starting from", value: "2,036,717" },
    { label: "10% Deposit", value: "203,672" },
    { label: "10% After 2 Years", value: "N/A" },
    { label: "7 Years", value: "229,131" },
    { label: "Maintenance", value: "305,508" },
    { label: "Club House", value: "80,000" },
    { label: "Status", value: "Available" },
    { label: "Finishing", value: "Fully Finished" },
    { label: "Delivery", value: "3 years" },
  ];
  return (
    <PageContainerComponent>
      <LocationIndicator text="U Green - E-2BD" fontSize={60} />
      <div className={styles.paymentContainer}>
        {labels_Values.map((item, index) => (
          <>
                      
            <Box className={styles.paymentColumn} key={index}>
              <Box
                as="p"
                className={styles.paymentColumnLabel}
                fontSize={{
                  tablet7: "1.6rem",
                  tablet16: "3rem",
                }}
              >
                {item.label}
              </Box>
              <Box
                as="p"
                className={styles.paymentColumnValue}
                fontSize={{
                  tablet7: "1.6rem",
                  tablet16: "3rem",
                }}
              >
                {item.value}
              </Box>
            </Box>
            <Divider color="brown" />
         
          </>
        ))}
      </div>
      <FloorPlateNavOptions isPaymentPage />
    </PageContainerComponent>
  );
}

export default PaymentPage;

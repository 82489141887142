import { HStack } from "@chakra-ui/react";

export interface ContainerProps {
  activateOption?: (x: any) => any;
  children?: any;
  isResidential?: boolean;
}

export default function Container(props: ContainerProps) {
  const stylingObj = {
    "& svg path": {
      fill: `#CCB288 !important`,
    },
    "&:hover svg path": {
      fill: `white !important`,
    },
    "&:focus svg path": {
      fill: `white !important`,
    },
    "&:active svg path": {
      fill: `white !important`,
    },
  };

  return (
    <HStack
      role="group"
      w={{ base: "20%", mobile8: "32%", tablet16: "32%" }}
      h="100%"
      flex={1}
      spacing="2px"
      justifyContent="center"
      bg="white"
      color="#CCB288"
      borderColor="#CCB288"
      borderWidth={{ base: "thin", mobile8: "medium" }}
      borderRadius={{ base: "0.7rem", tablet16: "1.5rem" }}
      p={{ base: "0.6rem", tablet16: "1.5rem" }}
      boxShadow={{
        tablet16: "2px 0px 2px 1px #CCB288",
      }}
      _active={{
        bg: "#CCB288",
        color: "white",
      }}
      _focus={{
        bg: "#CCB288",
        color: "white",
      }}
      _hover={{
        bg: "#CCB288",
        color: "white",
      }}
      onClick={props.activateOption}
      sx={!props.isResidential ? stylingObj : undefined}
    >
      {props.children}
    </HStack>
  );
}

import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "app/store";

export const enum AnimateState {
  AnimationStarted,
  AnimationStopped,
}

type ComponentAnimation = {
  IconAnimation: boolean;
};

const initialState: ComponentAnimation = {
  IconAnimation: false,
};

export const infoBoxSlice = createSlice({
  name: "infoBoxSlice",
  initialState,
  reducers: {
    startIconAnimation: (state: any) => {
      state.IconAnimation = true;
    },
    stopIconAnimation: (state: any) => {
      state.IconAnimation = false;
    },
  },
});

export const { startIconAnimation, stopIconAnimation } = infoBoxSlice.actions;

export const selectIconAnimationState = (state: RootState) => {
  return state.infoBoxAnimation.IconAnimation;
};

export default infoBoxSlice.reducer;

import { Route } from "react-router-dom";

// Pages.
import ProjectPlanPage from "pages/sun_capital/master-plane/Hani Saad/stand-alone/plan/ProjectPlanPage";
import ProjectGalleryPage from "pages/sun_capital/master-plane/Hani Saad/stand-alone/gallery/ProjectGalleryPage";
import PaymentPage from "pages/sun_capital/master-plane/Hani Saad/stand-alone/payment/PaymentPage";
const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/hani-saad/stand-alone/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/hani-saad/stand-alone/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/hani-saad/stand-alone/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

import { Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import { useChoseStyleOnLandscape } from "../../../../utils/Selecting";

import { selectErrorMsgVisibility } from "../sharingSlice";
import { showDisclaimer } from "../sharingSlice";

export default function SendingBtn() {
  // Displaying Disclaimer.
  const isEmailValid = useSelector(selectErrorMsgVisibility);

  const dispatch = useDispatch();

  const displayDisclaimer = () => {
    if (!isEmailValid) return dispatch(showDisclaimer());
  };

  // Landscape Styling.
  const containerStylesInLandscape = {
    borderRightRadius: {
      "1xsm": "0.5rem",
    },
    fontSize: {
      "1xsm": "0.6rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Button
      color="white"
      fontSize={["0.8rem", null, null, null, "0.9rem", null, null, "2rem"]}
      w="30%"
      h="70%"
      bg={"#CCB288"}
      _active={{
        bg: "#CCB288",
      }}
      _hover={{
        bg: "#CCB288",
      }}
      _focus={{
        outline: "none",
      }}
      onClick={displayDisclaimer}
      {...styles}
    >
      Send
    </Button>
  );
}

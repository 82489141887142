import { Icon } from "@chakra-ui/react";
import { IconsNames } from "../../pins/utils";

import SingleOptionButton from "../single-option-button/SingleOptionButton";

const Attractions = () => {
  return (
    <SingleOptionButton optionName={IconsNames.Attractions}>
      <Icon viewBox="0 0 24 24" fontSize="2rem">
        <g id="Attractions-Icon" transform="translate(0)">
          <g id="Group_3853" data-name="Group 3853" transform="translate(0 0)">
            <path
              id="Path_12486"
              data-name="Path 12486"
              d="M23.3,22.594H16.265l-.979-3.084a8.657,8.657,0,0,0,1.352-.7,2.109,2.109,0,1,0,2.686-2.686,8.621,8.621,0,0,0,1.137-2.737,2.109,2.109,0,1,0,0-3.8,8.623,8.623,0,0,0-1.137-2.737A2.109,2.109,0,1,0,16.638,4.16,8.623,8.623,0,0,0,13.9,3.023a2.109,2.109,0,1,0-3.8,0A8.624,8.624,0,0,0,7.362,4.16,2.109,2.109,0,1,0,4.676,6.846,8.622,8.622,0,0,0,3.539,9.583a2.109,2.109,0,1,0,0,3.8,8.621,8.621,0,0,0,1.137,2.737,2.109,2.109,0,1,0,2.686,2.686,8.657,8.657,0,0,0,1.352.7l-.979,3.084H.7A.7.7,0,0,0,.7,24H23.3a.7.7,0,1,0,0-1.406Zm-5.688-6.5-1-1a5.832,5.832,0,0,0,1.208-2.911h1.414A7.234,7.234,0,0,1,17.608,16.1Zm0-9.228a7.233,7.233,0,0,1,1.623,3.911H17.817A5.832,5.832,0,0,0,16.609,7.87ZM12.7,4.253a7.233,7.233,0,0,1,3.911,1.623l-1,1A5.832,5.832,0,0,0,12.7,5.667V4.253Zm3.75,7.231A4.458,4.458,0,0,1,14,15.462l-.729-2.3a2.109,2.109,0,1,0-2.543,0L10,15.462a4.453,4.453,0,1,1,6.454-3.978Zm-3.793,4.4a4.472,4.472,0,0,1-1.321,0l.66-2.08ZM12,17.344a5.866,5.866,0,0,0,1.09-.1l.428,1.348a7.28,7.28,0,0,1-3.036,0l.428-1.348a5.867,5.867,0,0,0,1.09.1ZM11.3,4.253V5.667A5.832,5.832,0,0,0,8.386,6.876l-1-1A7.233,7.233,0,0,1,11.3,4.253ZM6.392,6.87l1,1a5.832,5.832,0,0,0-1.208,2.911H4.769A7.233,7.233,0,0,1,6.392,6.87Zm0,9.228a7.233,7.233,0,0,1-1.623-3.911H6.183A5.832,5.832,0,0,0,7.391,15.1Zm.994.994,1-1a5.869,5.869,0,0,0,1.184.722l-.428,1.348a7.279,7.279,0,0,1-1.756-1.071Zm1.825,5.5.844-2.658a8.706,8.706,0,0,0,3.891,0l.844,2.658Zm5.219-5.778a5.869,5.869,0,0,0,1.184-.722l1,1a7.276,7.276,0,0,1-1.756,1.071Z"
              transform="translate(0 0)"
              fill="#ccb288"
            />
          </g>
        </g>
      </Icon>
    </SingleOptionButton>
  );
};

export default Attractions;

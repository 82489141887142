import { createContext, useState } from "react";
import { VStack } from "@chakra-ui/react";

// Custom Components
import HeroImage from "./hero-image/HeroImage";
import OptionsButtons from "./options-buttons/OptionsButtons/OptionsButtons";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "hooks/navigation/useVerifyAuth";

export const FacilityPageContext = createContext({
  facilityIconName: "none",
  setActiveIconName: (name: string) => {},
});

export default function ProjectFacilitiesPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const [activeIcon, setActiveIcon] = useState("none");

  return (
    <PageContainerComponent
      spacing={{
        base: "0rem",
        sm: "0.5rem",
        "2xl": "3rem",
      }}
      overflow="hidden"
    >
      <LocationIndicator text="Galleria" />
      <VStack
        w="full"
        h="87%"
        spacing={{
          base: "1rem",
          mobile8: "1.5rem",
          ipadPro: "3rem",
        }}
      >
        <FacilityPageContext.Provider value={{facilityIconName: activeIcon, setActiveIconName:setActiveIcon}}>
          <HeroImage />
          <OptionsButtons />
        </FacilityPageContext.Provider>
      </VStack>
    </PageContainerComponent>
  );
}

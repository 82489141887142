import { Box, VStack } from "@chakra-ui/react";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import React from "react";

function Page_360() {
  return (
    <PageContainerComponent
      spacing={{
        base: "0rem",
        lg: "1rem",
        xl: "3rem",
        tablet16: "0rem",
      }}
      h="87%"
      overflow={"hidden"}
    >
      <LocationIndicator text="360 View" />
      <VStack
        w="full"
        h="89%"
        spacing={{
          base: "0.5rem",
          xsm: "1.5rem",
          ipadPro: "3rem",
        }}
        borderRadius="20px"
      >
        <iframe
          src="https://arabia.soleekhub.com/SUN-CAPITAL/"
          title="360 view"
          width="100%"
          height="90%"
          style={{borderRadius:"20px", border: "#CCB288 1px solid"}}
        />
      </VStack>
    </PageContainerComponent>
  );
}

export default Page_360;

import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { Button, HStack, useToast, VStack } from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { DisplayBookingInfoProps } from "../../src/standalone-components/FormBookComp/BookComp/DisplayBookingInfo/DisplayBookingInfo";
import { updateUnitToItsPrevStatus } from "../standalone-components/FormBookComp/hooks/useMakeUnitUnavailable";
import { Status } from "interfaces/property";
import { FormInput } from "standalone-components/FormBookComp/FormInput/FormInput";
import LocationIndicator from "./sub-components/LocationIndicator/LocationIndicator";

export interface EnquiryProps extends DisplayBookingInfoProps {
  propertyId?: string;
}
export interface Client {
  fullname: string;
  email: string;
  phone: string;
  sales: string;
  blocked: boolean;
}

export default function EnquiryForm(props: EnquiryProps) {
  const history = useHistory();

  const toaster = useToast({
    description: "Booked Successfully",
    position: "top",
    duration: 3000,
    isClosable: true,
    title: "Success",
    status: "success",
  });

  const { register, handleSubmit } = useForm<Client>();
  const [isLoading, setIsLoading] = useState(false);
  async function createClient(clientData: Client): Promise<boolean | string> {
    const url = `https://celltek-realestate.herokuapp.com/properties/new-inquiry?fullname=${clientData.fullname}&email=${clientData.email}&phone=${clientData.phone}&sourcename=${clientData.sales}`;

    try {
      const response: any = await axios.post(url, {
        ...clientData,
      });
      return response.data?._id ?? false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const onCreateClient: SubmitHandler<Client> = async (data: Client) => {
    setIsLoading(true);

    const isClientCreated = await createClient(data);

    if (isClientCreated) {
      toaster({
        description: "Successfully Sent",
        position: "top",
        duration: 3000,
        isClosable: true,
        title: "Success",
        status: "success",
      });
      setIsLoading(false);
      history.goBack();
    } else { 
      toaster({
        description: "Successfully Sent",
        position: "top",
        duration: 3000,
        isClosable: true,
        title: "Success",
        status: "success",
      });
      setIsLoading(false);
      history.goBack();

    }
  };
  const onClose = () => {
    if (props.propertyId) {
      updateUnitToItsPrevStatus(props.propertyId, Status.AVAILABLE);
    }
  };
  return (
    <>
      <LocationIndicator text="Enquiry Form" />
      <form
        onSubmit={handleSubmit(onCreateClient)}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <VStack w="full" h="full">
          <HStack w="full" marginTop="1rem">
            <FormInput
              formRegisterFunc={register}
              placeholderText="Full Name"
              registerText="fullname"
              inputType="text"
              registerOptions={{ required: true }}
            />
          </HStack>
          <HStack w="full" marginTop="1rem">
            <FormInput
              formRegisterFunc={register}
              placeholderText="Phone Number"
              registerText="phone"
              inputType="tel"
            />
          </HStack>
          <HStack w="full" marginTop="1rem">
            <FormInput
              placeholderText="Email Address"
              formRegisterFunc={register}
              registerText="email"
              inputType="email"
            />
          </HStack>
          <HStack w="full" marginTop="1rem">
            <FormInput
              placeholderText="Source Name"
              formRegisterFunc={register}
              registerText="sales"
              inputType="text"
            />
          </HStack>
          <HStack justifyContent="center" w="full" marginTop="3rem !important">
            <Button
              bgColor="white"
              onClick={onClose}
              w="full"
              color="#CCB288"
              fontSize="2rem"
              fontWeight={500}
              padding="3rem"
              borderRadius="20px"
            >
              Cancel
            </Button>
            <Button
              bgColor="#CCB288"
              type="submit"
              w="full"
              color="white"
              isLoading={isLoading}
              fontSize="2rem"
              fontWeight={500}
              padding="3rem"
              borderRadius="20px"
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </form>
    </>
  );
}

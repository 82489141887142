import { Route } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/sun_capital/master-plane/cyra/Type-8/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/sun_capital/master-plane/cyra/Type-8/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/sun_capital/master-plane/cyra/Type-8/payment/PaymentPage';

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/cyra/type-8/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/cyra/type-8/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/cyra/type-8/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

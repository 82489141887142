import { Image } from "@chakra-ui/react";

// Stylings
import externalStyles from "./CompanyLogo.module.css";

// Utils
import NavBox from "../../../utils/NavigationComps/NavBox";

// Images
import Company_logo_new from "../../../img/common/company_logos/Arabia logo-01.png";

export default function CompanyLogo() {
  return (
    <NavBox
      pos="relative"
      to="/location-page"
      w="100%"
      h="100%"
      py={{ tablet7: "1.5rem", tablet16: "2.5rem" }}
    >
      <Image
        className={externalStyles.logo}
        loading="lazy"
        src={Company_logo_new}
        h="full"
        w={{
          base: "50%",
          "1xsm": "40%",
          mobile1: "47%",
          mobile7: "40%",
          mobile8: "45%",
          mobile13: "40%",
          mobile10: "19%",
          mobile14: "16%",
          mobile15: "16%",
          mobile16: "40%",
          tablet2: "40%",
          tablet4: "15%",
          tablet7: "18rem",
          tablet10: "18%",
          tablet13: "37%",
          tablet16: "22rem",
          tablet19: "17%",
          desktop5: "21%",
        }}
      />
    </NavBox>
  );
}

import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/galleria/master-plane/zone-4/Type-G/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/galleria/master-plane/zone-4/Type-G/gallery//ProjectGalleryPage';
import PaymentPage from 'pages/galleria/master-plane/zone-4/Type-G/payment/PaymentPage';
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-4/type-g/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-4/type-g/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-4/type-g/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

import { Box, Image, Skeleton } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import { MasterPlanImageContext } from '../ProjectZonePage';

import ZoneImg from 'img/galleria/master-plan/zone-6/Zone-6-Master-Plan.png';
import ZoneTypeC from 'img/galleria/master-plan/zone-6/Zone-6-Master-Plan-Type-C.png';
import ZoneTypeD from 'img/galleria/master-plan/zone-6/Zone-6-Master-Plan-Type-D.png';
import ZoneTypeE from 'img/galleria/master-plan/zone-6/Zone-6-Master-Plan-Type-E.png';
import ZoneTypeG from 'img/galleria/master-plan/zone-6/Zone-6-Master-Plan-Type-G.png';
import ZoneTypeH from 'img/galleria/master-plan/zone-6/Zone-6-Master-Plan-Type-H.png';

export default function Img() {
  const [isLoaded, setIsLoaded] = useState(false);

  const { typeName } = useContext(MasterPlanImageContext);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
          objectFit="fill"
          src={selectImage(typeName)}
        />
      </Box>
    </Skeleton>
  );
}

function selectImage(zoneType: string) {
  switch (zoneType) {
    case 'type-c':
      return ZoneTypeC;
    case 'type-d':
      return ZoneTypeD;
    case 'type-e':
      return ZoneTypeE;
    case 'type-g':
      return ZoneTypeG;
    case 'type-h':
      return ZoneTypeH;
    default:
      return ZoneImg;
  }
}

import { Route } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/sun_capital/master-plane/Aurora/stand-alone/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/sun_capital/master-plane/Aurora/stand-alone/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/sun_capital/master-plane/Aurora/stand-alone/payment/PaymentPage';
const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/aurora/stand-alone/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/aurora/stand-alone/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/aurora/stand-alone/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

import { HStack, Heading, Button, Icon } from "@chakra-ui/react";
import { IoCloseSharp } from "react-icons/io5";

import { useDispatch } from "react-redux";
import { hideSharing } from "./sharingSlice";

export default function Header() {
  const dispatch = useDispatch();

  function hideComponent() {
    return dispatch(hideSharing());
  }

  return (
    <HStack w="100%" h="30%">
      <Heading
        fontSize={[
          "1.3rem",
          "1.4rem",
          "1.4rem",
          "1.4rem",
          "1.5rem",
          "1.6rem",
          "1.6rem",
          "1.6rem",
          "2.5rem",
          null,
          "4rem",
        ]}
        w="90%"
        color="#CCB288"
      >
        Sharing via
      </Heading>
      <Button
        onClick={hideComponent}
        w={["10%", "15%", "15%", "15%", "15%", "13%", "13%"]}
        h={["4.5vh", "70%", "70%", "70%", "70%", "70%"]}
        bg="#CCB288"
        p="1.5%"
      >
        <Icon as={IoCloseSharp} w="full" h="full" color="white" />
      </Button>
    </HStack>
  );
}

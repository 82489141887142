import { Button, Center, Image } from "@chakra-ui/react";

// Icon.
import View360img from "img/galleria/common/View360img.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import NavBox from "utils/NavigationComps/NavBox";

export const View360Btn = ({ location }: { location: boolean }) => {
  
  const Btn = () => (
    <NavBox to="/sun_capital/view-360">
      <Button
        mr="1rem"
        w="8rem"
        h="7rem"
        borderRadius="0.8rem"
        bgColor="transparent"
        borderColor="white"
        borderWidth="0.2rem"
      >
        <Center w="full" h="full" py="8px">
          <Image
            w="full"
            h="70%"
            objectFit="fill"
            src={View360img}
            alt="360 button"
          />
        </Center>
      </Button>
    </NavBox>
  );

  return <Btn />;
};

import { Box, Image, Skeleton } from "@chakra-ui/react";
import { useContext, useState } from "react";

import { MasterPlanImageContext } from "../ProjectZonePage";

import ZoneImg from "img/Galleria zones/Zone 7/map/Zone 7.jpg";
import ZoneTypeB from "img/Galleria zones/Zone 7/map/Type-B.jpg";
import ZoneTypeD from "img/Galleria zones/Zone 7/map/Type-D.jpg";

export default function Img() {
  const [isLoaded, setIsLoaded] = useState(false);

  const { typeName } = useContext(MasterPlanImageContext);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "3rem" }}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "3rem" }}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "3rem" }}
          objectFit="fill"
          src={selectImage(typeName)}
        />
      </Box>
    </Skeleton>
  );
}

function selectImage(zoneType: string) {
  switch (zoneType) {
    case "type-b":
      return ZoneTypeB;
    case "type-d":
      return ZoneTypeD;
    default:
      return ZoneImg;
  }
}

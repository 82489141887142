import { Icon } from "@chakra-ui/react";
import { IconsNames } from "../../pins/utils";

import SingleOptionButton from "../single-option-button/SingleOptionButton";

const Connectivity = () => {
  return (
    <SingleOptionButton optionName={IconsNames.Connectivity}>
      <Icon viewBox="0 0 24 24" fontSize="2rem">
        <g id="Connectivity-Icon" transform="translate(-0.538 -0.538)">
          <g id="Layer_x0020_1" transform="translate(0.538 0.538)">
            <g id="_218022968">
              <path
                id="_218023232"
                d="M10.292,4.282H.538V8.2h9.753a.288.288,0,0,1,.288.288V24.538H14.5V21.3a.288.288,0,0,1,.288-.288h9.754V17.093H14.785a.287.287,0,0,1-.288-.287V.538H10.58V3.994a.288.288,0,0,1-.288.288ZM8.8,6.24a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1A.288.288,0,0,1,8.8,6.24Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576h1a.288.288,0,0,1,.288.288Zm-2.735,0a.288.288,0,0,1-.288.288h-1a.288.288,0,1,1,0-.576H3.039a.288.288,0,0,1,.288.288ZM16.568,18.764h1a.288.288,0,1,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-4.2.843V18.418a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm0-3.237V15.181a.288.288,0,1,1,.576,0V16.37a.288.288,0,0,1-.576,0Zm0-3.237V11.944a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-2.949a.288.288,0,0,1-.288-.288V8.706a.288.288,0,1,1,.576,0V9.9A.288.288,0,0,1,12.657,10.184Zm0-3.237a.288.288,0,0,1-.288-.288V5.469a.288.288,0,1,1,.576,0V6.658A.288.288,0,0,1,12.657,6.946ZM19.3,18.764h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm2.735,0h1a.288.288,0,0,1,0,.576h-1a.288.288,0,1,1,0-.576Zm-9.669,4.081V21.655a.288.288,0,1,1,.576,0v1.189a.288.288,0,0,1-.576,0Zm.288-19.135a.288.288,0,0,1-.288-.288V2.232a.288.288,0,1,1,.576,0V3.421A.288.288,0,0,1,12.657,3.709Z"
                transform="translate(-0.538 -0.538)"
                fill="#ccb288"
              />
              <path
                id="_218023352"
                d="M5.079,2.466A1.381,1.381,0,1,0,6.46,1.086a1.383,1.383,0,0,0-1.381,1.38Z"
                transform="translate(13.873 1.2)"
                fill="#ccb288"
              />
              <path
                id="_218022920"
                d="M4.531,4.2c0,1,1.928,4.809,3.08,7.087.22.435.421.833.586,1.165.165-.332.366-.73.586-1.165C9.936,9.014,11.865,5.2,11.865,4.2a3.667,3.667,0,0,0-7.333,0ZM8.2,6.161A1.957,1.957,0,1,1,10.155,4.2,1.959,1.959,0,0,1,8.2,6.161Z"
                transform="translate(12.135 -0.538)"
                fill="#ccb288"
              />
              <path
                id="_218023040"
                d="M2.467,3.892A1.381,1.381,0,1,0,3.848,5.273,1.382,1.382,0,0,0,2.467,3.892Z"
                transform="translate(1.2 10.105)"
                fill="#ccb288"
              />
              <path
                id="_218022824"
                d="M4.2,8.968A1.957,1.957,0,1,1,6.161,7.011,1.959,1.959,0,0,1,4.2,8.968Zm0-5.624A3.671,3.671,0,0,0,.538,7.011c0,1,1.928,4.809,3.08,7.086.22.435.422.833.586,1.166.165-.332.366-.73.586-1.165,1.152-2.277,3.08-6.089,3.08-7.087A3.671,3.671,0,0,0,4.2,3.344Z"
                transform="translate(-0.538 8.366)"
                fill="#ccb288"
              />
            </g>
          </g>
        </g>
      </Icon>
    </SingleOptionButton>
  );
};

export default Connectivity;

import { Route } from 'react-router-dom';

import E1BD from './E-1BD';
import E2BD from './E-2BD';
import ES from './E-S';

// Pages.
import ZonePage from 'pages/sun_capital/master-plane/U Green/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/u-green`}>
        <ZonePage />
      </Route>
      <E1BD />
      <E2BD />
      <ES />
    </>
  );
};

export default Index;

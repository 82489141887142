import { Suspense, useEffect } from "react";

//? Header Child elements
import Container from "./Container";
import View360Btn from "./View360Btn";
import CompanyLogo from "./CompanyLogo";
import ZoneToggleBtn from "./ZoneToggleBtn";
import LazyLoadingComp from "../LazyLoadingComp";

import { ShareBtn } from "./ShareBtn";
import { FormBookCompProps } from "../../../standalone-components/FormBookComp/FormBookComp";
import BookDisplayTriggerButton from "standalone-components/FormBookComp/BookDisplayTriggerButton";
import { useLocation } from "react-router-dom";

export interface HeaderProps {
  formData?: FormBookCompProps;
  zoneName?: any;
}

const CheckIfLastParamIsMasterPlane = () => {
  const location = useLocation();
  useEffect(() => {}, [location]);

  if (location && location.pathname.split("/")[4]) {
    return (
      <>
        <BookDisplayTriggerButton />
        <ShareBtn />
      </>
    );
  } else {
    return (
      <>
        {location.pathname.includes("sun_capital") ? (
          <View360Btn location={true} />
        ) : null}
        <ZoneToggleBtn />
      </>
    );
  }
};

export default function Header(props: HeaderProps) {
  return (
    <Suspense fallback={<LazyLoadingComp />}>
      <Container>
        <CompanyLogo />
        <CheckIfLastParamIsMasterPlane />
      </Container>
    </Suspense>
  );
}

import { createContext, useState } from "react";
import { VStack } from "@chakra-ui/react";

// Custom Components
import HeroImage from "./hero-image/HeroImage";
import OptionsContainer from "./options-container/OptionsContainer";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "hooks/navigation/useVerifyAuth";

export const MasterPlanImageContext = createContext({
  typeName: "none",
  setTypeName: (typeName: string) => {},
});

export default function ProjectZonePage() {
  // Auth before accessing this page
  useVerifyAuth();

  const [masterPlanImage, setMasterPlanImage] = useState("none");

  return (
    <PageContainerComponent spacing={{ base: "0rem" }} overflow="scroll">
      <LocationIndicator text="Cyra" />
      <VStack w="full" h="90%" spacing={0}>
        <MasterPlanImageContext.Provider
          value={{ typeName: masterPlanImage, setTypeName: setMasterPlanImage }}
        >
          <HeroImage />
          <OptionsContainer />
        </MasterPlanImageContext.Provider>
      </VStack>
    </PageContainerComponent>
  );
}

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Center, Image } from "@chakra-ui/react";

// Redux.
import { useDispatch, useSelector } from "react-redux";
import {
  activeGalleria,
  idleGalleria,
  isGalleriaImgActive,
} from "app/master-paln-img/master-plan-img.slice";

// Icon.
import viewIcon from "img/galleria/common/view-icon.png";
import viewIconActive from "img/galleria/common/view-icon-active.png";

export const ZoneToggleBtn = () => {
  const [currentViewIcon, setCurrentViewIcon] = useState(viewIcon);

  const history = useHistory();

  const [shouldDisplay, setShouldDisplay] = useState(
    isMasterPlanPage(history.location.pathname)
  );

  useEffect(() => {
    history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`);
      setShouldDisplay(isMasterPlanPage(history.location.pathname));
    });
  }, [history]);

  const isGalleriaActive = useSelector(isGalleriaImgActive);

  const dispatch = useDispatch();

  const onClick = () => {
    handleGalleria();
  };

  const handleGalleria = () => {
    if (isGalleriaProject()) {
      if (isGalleriaActive) {
        setCurrentViewIcon(viewIcon);
        dispatch(idleGalleria());
      } else {
        setCurrentViewIcon(viewIconActive);
        dispatch(activeGalleria());
      }
    }
  };

  const onMouseEnter = () => {
    if (isGalleriaActive) return;
    setCurrentViewIcon(viewIconActive);
  };

  const onMouseLeave = () => {
    if (isGalleriaActive) return;
    setCurrentViewIcon(viewIcon);
  };

  return (
    <Button
      display={!shouldDisplay ? "none" : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      w="8rem"
      h="7rem"
      borderRadius="0.8rem"
      bgColor={isGalleriaActive ? "white" : "transparent"}
      borderColor="white"
      borderWidth="0.2rem"
    >
      <Center w="full" h="full" py="1.1rem">
        <Image
          w="full"
          h="70%"
          objectFit="fill"
          src={currentViewIcon}
          alt="360 button"
        />
      </Center>
    </Button>
  );
};

const isGalleriaProject = () => {
  return true;
};

export const isMasterPlanPage = (pathname: string) => {
  const urlValues = pathname.split("/");
  const isMasterPlan = urlValues[2] === "master-plan";
  const isZone = !!urlValues[3];

  return isMasterPlan && !isZone;
};

import { memo } from "react";

import loadable from "@loadable/component";

// Custom Components
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

const Map = loadable(() => import("./map/Map"));

function LocationPage() {
  return (
    <PageContainerComponent>
      <Map />
    </PageContainerComponent>
  );
}

export default memo(LocationPage);

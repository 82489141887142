import { VStack, HStack } from "@chakra-ui/react";

import Medical from "./ButtonsWithIcons/Medical";
import Shopping from "./ButtonsWithIcons/Shopping";
import Education from "./ButtonsWithIcons/Education";
import Landmarks from "./ButtonsWithIcons/Landmarks";
import Attractions from "./ButtonsWithIcons/Attractions";
import Connectivity from "./ButtonsWithIcons/Connectivity";

export default function OptionsButtons() {
  return (
    <VStack
      w="100%"
      h={{ base: "13%", mobile8: "15%", tablet16: "13%" }}
      spacing={{ base: "0.5rem", tablet16: "1rem" }}
      flex={{ base: 4 }}
    >
      <HStack w="97%" h="49%" spacing={{ base: "0.5rem", tablet16: "1rem" }}>
        <Shopping />
        <Education />
        <Landmarks />
      </HStack>
      <HStack w="97%" h="49%" spacing={{ base: "0.5rem", tablet16: "1rem" }}>
        <Attractions />
        <Medical />
        <Connectivity />
      </HStack>
    </VStack>
  );
}

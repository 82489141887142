import { useRouteMatch } from "react-router-dom";

// Custom Components.
import OptionsRow from "./OptionsRow";
import BuildingOption from "./BuildingOption";
import OptionsContainer from "./OptionsContainer";

//? Image Sources
// Zone 1
import PropertyType1 from "img/sun-capital/Master plan/Cyra/TYPE 2/Gallery/4.jpg";
// import PropertyType2 from "img/sun-capital/Master plan/Cyra/TYPE 5/Gallery/1.jpg";
import PropertyType3 from "img/sun-capital/Master plan/Cyra/Type 6/Gallery/2.jpg";
import PropertyType4 from "img/sun-capital/Master plan/Cyra/TYPE 7/Gallary/4.jpg";
import PropertyType5 from "img/sun-capital/Master plan/Cyra/TYPE 8/Gallery/4.jpg";
import PropertyType6 from "img/sun-capital/Master plan/Cyra/TYPE 9/Gallery/3.jpg";
import PropertyType7 from "img/sun-capital/Master plan/Cyra/TYPE 10/Gallery/3.jpg";
import PropertyType8 from "img/sun-capital/Master plan/Cyra/TYPE 14/Gallery/4.jpg";
import PropertyType9 from "img/sun-capital/Master plan/Cyra/TYPE 16/Gallery/3.jpg";
import PropertyType10 from "img/sun-capital/Master plan/Cyra/TYPE 18/Gallary/3.jpg";
import { Box } from "@chakra-ui/react";

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-2"
          planUrl={`${path}/type-2/plan`}
          buildingName="Type 2"
          image={PropertyType1}
        />
        {/* <BuildingOption
          typeName="type-5"
          planUrl={`${path}/type-5/plan`}
          buildingName="Type 5"
          image={PropertyType2}
        /> */}
        <BuildingOption
          typeName="type-6"  //state
          planUrl={`${path}/type-6/plan`}
          buildingName="Type 6"
          image={PropertyType3}
        />
      </OptionsRow>
      <OptionsRow>
        
        <BuildingOption
          typeName="type-7"
          planUrl={`${path}/type-7/plan`}
          buildingName="Type 7"
          image={PropertyType4}
        />
        <BuildingOption
          typeName="type-8"
          planUrl={`${path}/type-8/plan`}
          buildingName="Type 8"
          image={PropertyType5}
        />

      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="type-9"
          planUrl={`${path}/type-9/plan`}
          buildingName="Type 9"
          image={PropertyType6}
        />
        <BuildingOption
          typeName="type-10"
          planUrl={`${path}/type-10/plan`}
          buildingName="Type 10"
          image={PropertyType7}
        />
      </OptionsRow>
      <OptionsRow>
        
        <BuildingOption
          typeName="type-14"
          planUrl={`${path}/type-14/plan`}
          buildingName="Type 14"
          image={PropertyType8}
        />
        <BuildingOption
          typeName="type-16"
          planUrl={`${path}/type-16/plan`}
          buildingName="Type 16"
          image={PropertyType9}
        />
      </OptionsRow>
      <OptionsRow>
        
        <BuildingOption
          typeName="type-18"
          planUrl={`${path}/type-18/plan`}
          buildingName="Type 18"
          image={PropertyType10}
        />
        <Box  
        flex={1}
        h="100%"
        />
      </OptionsRow>
    </OptionsContainer>
  );
}

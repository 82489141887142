import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

// Images.
import ZoneType5 from 'img/sun-capital/Master plan/Zone elio final/Type 5 Cover.jpg';
import ZoneType15 from 'img/sun-capital/Master plan/Zone elio final/Type 15 cover.jpg';

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-5"
          planUrl={`${path}/type-5/plan`}
          buildingName="Type-5"
          image={ZoneType5}
        />
        <BuildingOption
          typeName="type-15"
          planUrl={`${path}/type-15/plan`}
          buildingName="Type-15"
          image={ZoneType15}
        />
      </OptionsRow>
    </OptionsContainer>
  );
}

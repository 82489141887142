import React, { Suspense } from "react";
import { VStack } from "@chakra-ui/react";

import CtxImage from "./CtxImage";
import LoadingComp from "components/common/loading-comp/LoadingComp";

const ProjectPins = React.lazy(() => import("./project-pins/ProjectPins"));

export default function NewCairoHeroImage() {
  return (
    <VStack
      w="100%"
      h={{ base: "70%", sm: "80%", tablet16: "75%" }}
      position="relative"
    >
      <CtxImage />
      <Suspense fallback={<LoadingComp />}>
        <ProjectPins />
      </Suspense>
    </VStack>
  );
}

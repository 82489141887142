import { useEffect } from "react";
import { VStack } from "@chakra-ui/react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { stopIconAnimation } from "./PageInfo/page-info-in-boxs/InfoBox/InfoBoxSlice";
import { selectIconAnimationState } from "./PageInfo/page-info-in-boxs/InfoBox/InfoBoxSlice";

// Custom Components
import PageInfo from "./PageInfo/PageInfo";
import OptionsButtons from "./OptionsButtons";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "hooks/navigation/useVerifyAuth";

export default function ProjectPresentationPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const isBoxesAnimation = useSelector(selectIconAnimationState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isBoxesAnimation) dispatch(stopIconAnimation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainerComponent
      overflow="scroll"
      spacing={{
        base: "unset",
        sm: "1.2rem",
        lg: "unset",
      }}
      h={{
        base: "90%",
      }}
    >
      <LocationIndicator pageUrl="/location-page" text="Sun Capital" />
      <VStack overflow="scroll" w="full" h={{ base: "full" }}>
        <PageInfo />
        <OptionsButtons />
      </VStack>
    </PageContainerComponent>
  );
}

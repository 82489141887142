import { useCallback, useEffect } from "react";
import { Box, Center, useMediaQuery } from "@chakra-ui/react";

import GoogleMapsReact from "google-map-react";

import "./GoogleMapComp.css";

export default function GoogleMapComp(props: any) {
  useEffect(() => {
    console.clear();
  }, []);

  const newCairoLocation = useCallback(
    () => props.sun ? getLocation(29.936605347401052, 31.09706049813914) : getLocation(30.026954392233005, 31.499214986762144),
    []
  );
  const center = newCairoLocation();

  // Change Zoom on mobile screen sizes.
  const [isMaxW450] = useMediaQuery("(max-width:450px)");

  const zoomLevel = isMaxW450 ? 11 : 13;
  return (
    <Box w="full" h="full" bg="blue.500">
      <Center id="google-map" w="full" h="full">
        <Box
          as={GoogleMapsReact}
          bootstrapURLKeys={{ key: "AIzaSyCe-gBIMId-D1eLjQfHJKjwc1TYtRG0CZQ" }}
          defaultZoom={zoomLevel}
          defaultCenter={center}
          zoom={13}
        >
          {props.children}
        </Box>
      </Center>
    </Box>
  );
}

function getLocation(lat: number, lng: number) {
  return { lat, lng };
}

import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

//? Image Sources
// Zone 1
import PropertyType1 from 'img/Galleria zones/Zone 1/Type E main cover.jpg';
import PropertyType2 from 'img/Galleria zones/Zone 1/Type E main cover.jpg';
import PropertyType3 from 'img/Galleria zones/Zone 1/Type E main cover.jpg';
import PropertyType4 from 'img/Galleria zones/Zone 1/Type E main cover.jpg';

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-c"
          planUrl={`${path}/type-c/plan`}
          buildingName="Type C"
          image={PropertyType1}
        />
        <BuildingOption
          typeName="type-d"
          planUrl={`${path}/type-d/plan`}
          buildingName="Type D"
          image={PropertyType2}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="type-e"
          planUrl={`${path}/type-e/plan`}
          buildingName="Type E"
          image={PropertyType3}
        />
        <BuildingOption
          typeName="type-h"
          planUrl={`${path}/type-h/plan`}
          buildingName="Type H"
          image={PropertyType4}
        />
      </OptionsRow>
    </OptionsContainer>
  );
}

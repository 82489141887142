import { memo } from "react";

import Zone from "./Zone";

function Zones() {
  return (
    <>
      <Zone
        location={{
          top: {
            tablet7: "6.5rem",
            tablet16: "9rem",
          },
          left: {
            tablet7: "36rem",
            tablet16: "43rem",
          },
        }}
        zoneNumber={1}
      />
      <Zone
        location={{
          top: { tablet7: "12.5%", tablet16: "12%" },
          left: { tablet7: "7%", tablet16: "5%" },
        }}
        zoneNumber={2}
      />
      <Zone
        location={{
          top: { tablet7: "41%", tablet16: "40%" },
          left: { tablet7: "74%", tablet16: "72%" },
        }}
        zoneNumber={3}
      />
      <Zone
        location={{
          top: { tablet7: "42%", tablet16: "40%" },
          left: { tablet7: "8%", tablet16: "8%" },
        }}
        zoneNumber={4}
      />
      <Zone
        location={{
          top: { tablet7: "65%", tablet16: "65%" },
          left: { tablet7: "71%", tablet16: "70%" },
        }}
        zoneNumber={5}
      />
      <Zone
        location={{
          top: { tablet7: "67%", tablet16: "65%" },
          left: { tablet7: "6%", tablet16: "5%" },
        }}
        zoneNumber={6}
      />
      <Zone
        location={{
          top: { tablet7: "41%", tablet16: "40%" },
          left: "44%",
        }}
        zoneNumber={7}
      />
    </>
  );
}

export default memo(Zones);

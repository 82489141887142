import { createContext, useState } from "react";
import { VStack } from "@chakra-ui/react";

// Custom Hooks
import useVerifyAuth from "hooks/navigation/useVerifyAuth";

// Custom Components
import SelectedHeroImage from "./SelectedHeroImage";
import OptionsButtons from "./options-buttons/OptionsButtons";
import LocationIndicator from "components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "components/common/page-container-component/PageContainerComponent";

export const LocationPageContext = createContext({
  activeIconName: "none",
  setActiveIconName: (name: string) => {},
});

export default function ProjectLocationPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const [activeIcon, setActiveIcon] = useState("none");

  return (
    <PageContainerComponent
      spacing={{
        base: "0rem",
        lg: "1rem",
        xl: "3rem",
        tablet16: "0rem",
      }}
      h="87%"
      overflow={"hidden"}
    >
      <LocationIndicator text="Location" />
      <VStack
        w="full"
        h="89%"
        spacing={{
          base: "0.5rem",
          xsm: "1.5rem",
          ipadPro: "3rem",
        }}
      >
        <LocationPageContext.Provider
          value={{
            activeIconName: activeIcon,
            setActiveIconName: setActiveIcon,
          }}
        >
          <SelectedHeroImage />
          <OptionsButtons />
        </LocationPageContext.Provider>
      </VStack>
    </PageContainerComponent>
  );
}

import { Icon } from "@chakra-ui/react";
import { IconsNames } from "../../pins/utils";

import SingleOptionButton from "../single-option-button/SingleOptionButton";

const Landmarks = () => {
  return (
    <SingleOptionButton optionName={IconsNames.Landmarks}>
      <Icon viewBox="0 0 24 24" fontSize="2rem">
        <g id="Landmarks-Icon" transform="translate(0.001 0)">
          <path
            id="Path_12484"
            data-name="Path 12484"
            d="M310.816,150.388a.7.7,0,0,0-.629-.389h-6.375v2.109a2.112,2.112,0,0,1-2.109,2.109H301v2.109a.7.7,0,0,0,.7.7h8.484a.7.7,0,0,0,.562-1.125l-1.793-2.391,1.793-2.391A.7.7,0,0,0,310.816,150.388Zm0,0"
            transform="translate(-286.891 -142.969)"
            fill="#ccb288"
          />
          <path
            id="Path_12485"
            data-name="Path 12485"
            d="M23.3,22.594H12.606c-.4-1.461-2.035-2.557-4.122-2.763V9.844h6.328a.7.7,0,0,0,.7-.7V3.515a.7.7,0,0,0-.7-.7H9.761a2.086,2.086,0,0,0,.129-.7,2.109,2.109,0,1,0-2.812,1.98V19.83c-2.088.207-3.722,1.3-4.122,2.763H.7A.7.7,0,0,0,.7,24H23.3a.7.7,0,0,0,0-1.406Zm0,0"
            transform="translate(0)"
            fill="#ccb288"
          />
        </g>
      </Icon>
    </SingleOptionButton>
  );
};

export default Landmarks;

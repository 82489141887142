import { Route } from "react-router-dom";

import TypeC from "../zone-1/Type-C/index";
import TypeD from "../zone-1/Type-D/index";
import TypeE from "../zone-1/Type-E/index";
import TypeH from "../zone-1/Type-H/index";

// Pages.
import ZonePage from "pages/galleria/master-plane/zone-1/ProjectZonePage";

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-1`}>
        <ZonePage />
      </Route>
      <TypeC />
      <TypeD />
      <TypeE />
      <TypeH />
    </>
  );
};

export default Index;

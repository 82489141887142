import { RootState } from "../store";

import { createSlice } from "@reduxjs/toolkit";
import { IconsNames } from "pages/galleria/location/pins/utils";

export const enum ActiveState {
  Active = 1,
  NotActive = 0,
}

export interface ActiveIcons {
  Retail: ActiveState;
  Medical: ActiveState;
  Education: ActiveState;
  Landmarks: ActiveState;
  Attractions: ActiveState;
  Connectivity: ActiveState;
  Point90: ActiveState;
}

const initialState: ActiveIcons = {
  Retail: ActiveState.NotActive,
  Medical: ActiveState.NotActive,
  Education: ActiveState.NotActive,
  Landmarks: ActiveState.NotActive,
  Attractions: ActiveState.NotActive,
  Connectivity: ActiveState.NotActive,
  Point90: ActiveState.NotActive,
};

export const activeIconsSlice = createSlice({
  name: "activeIcons",
  initialState,
  reducers: {
    // Activate Logic
    activateIcon: (
      state: ActiveIcons,
      action: { type: string; payload: IconsNames }
    ) => {
      state.Attractions = ActiveState.NotActive;
      state.Connectivity = ActiveState.NotActive;
      state.Education = ActiveState.NotActive;
      state.Landmarks = ActiveState.NotActive;
      state.Medical = ActiveState.NotActive;
      state.Retail = ActiveState.NotActive;
      // state.Point90 = ActiveState.NotActive;
      state[action.payload] = ActiveState.Active;
    },
    // Deactivate Logic
    deactivateIcon: (
      state: ActiveIcons,
      action: { type: string; payload: IconsNames }
    ) => {
      state.Attractions = ActiveState.NotActive;
      state.Connectivity = ActiveState.NotActive;
      state.Education = ActiveState.NotActive;
      state.Landmarks = ActiveState.NotActive;
      state.Medical = ActiveState.NotActive;
      state.Retail = ActiveState.NotActive;
      // state.Point90 = ActiveState.NotActive;
      state[action.payload] = ActiveState.NotActive;
    },
    deactivateAllIcon: (state: any) => {
      state = initialState;
    },
  },
});

// Reducers
export const { activateIcon, deactivateIcon, deactivateAllIcon } =
  activeIconsSlice.actions;

export const selectActiveIcons = (state: RootState) => state.activeIcons;

export default activeIconsSlice.reducer;

import { Route } from 'react-router-dom';

import TypeD from './Type-D';
import TypeE from './Type-E';
import TypeG from './Type-G';
import TypeH from './Type-H';

// Pages.
import ZonePage from 'pages/galleria/master-plane/zone-5/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-5`}>
        <ZonePage />
      </Route>
      <TypeD />
      <TypeE />
      <TypeG />
      <TypeH />
    </>
  );
};

export default Index;

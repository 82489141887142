// HTTP-Requests
import axios from "axios";

// Navigation
import { useHistory } from "react-router-dom";

// Interfaces.
import Admin from "interfaces/Admin";

// Constants.
import baseURL from "constants/baseURL";

export default function useVerifyAuth() {
  const history = useHistory();

  const adminUsername = localStorage.getItem("admin_username") || undefined;
  const adminType = localStorage.getItem("admin_type") || undefined;

  const admin: Partial<Admin> = {
    username: adminUsername,
    adminType,
  };

  const adminFromToken = verifyFromToken();

  if (hasUsernameAndType(admin)) {
    return admin;
  } else if (adminFromToken) {
    return adminFromToken;
  } else {
    history.push("/login");
  }
}

function hasUsernameAndType(admin: Admin) {
  return admin.username && admin.adminType;
}

function verifyFromToken() {
  const url = `${baseURL}/auth/login/real-estate/profile`;

  let admin: Admin | null = null;

  axios
    .get<Admin>(url, {
      withCredentials: true,
    })
    .then((response) => {
      admin = response.data;
    })
    .catch((error) => {
      admin = null;
    });

  return admin;
}

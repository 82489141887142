import { Box, Image, Skeleton } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import { MasterPlanImageContext } from '../ProjectZonePage';

import ZoneImg from 'img/sun-capital/Master plan/Horizontal/Horizontal zone.jpg';
import ZoneTypeD from 'img/Zones_SC/Horizontal - 108.svg';
import ZoneTypeE from 'img/Zones_SC/Horizontal 109.svg';

export default function Img() {
  const [isLoaded, setIsLoaded] = useState(false);

  const { typeName } = useContext(MasterPlanImageContext);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{ base: '1rem', mobile8: '2rem', tablet16: '3rem' }}
          objectFit="cover"
          src={selectImage(typeName)}
        />
      </Box>
    </Skeleton>
  );
}

function selectImage(zoneType: string) {
  switch (zoneType) {
    case '108-building':
      return ZoneTypeD;
    case '109-building':
      return ZoneTypeE;
    default:
      return ZoneImg;
  }
}

import { VStack } from "@chakra-ui/react";

// Redux.
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { isGalleriaImgActive } from "app/master-paln-img/master-plan-img.slice";

import ZoneImage from "./ZoneImage";
import ZonesContainer from "./ZonesContainer";

export default function NewCairoHeroImage() {
  const isGalleriaActive = useSelector((state: RootState) =>
    isGalleriaImgActive(state)
  );

  return (
    <VStack w="100%" h={{ base: "80%" }} position="relative">
      <ZoneImage />
      {isGalleriaActive && <ZonesContainer />}
    </VStack>
  );
}

import { memo } from "react";
import { VStack } from "@chakra-ui/react";

// Styling
import externalStyles from "./PageInfoInBoxes.module.css";

// Utils
import { useChoseStyleOnLandscape } from "utils/Selecting";

// Custom Components
import BoxesRow from "./BoxesRow";
import InfoBox from "./InfoBox/InfoBox";

// Icons
import { GiPathDistance, GiVillage } from "react-icons/gi";
import { MdOutlineApartment } from "react-icons/md";

function PageInfoInBoxes() {
  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      base: "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <VStack
      className={externalStyles.page_info_in_boxes}
      w="100%"
      h={{ base: "46%" }}
      spacing={{
        base: "1rem",
        sm: "2rem",
        lg: "2.5rem",
        tablet16: "1rem",
      }}
      {...styles}
    >
      <BoxesRow>
        <InfoBox
         fontSize={{
          base: "0.75rem",
          sm: "1.1rem",
          miniMobile10: "0.6rem",
          tablet16: "1rem",
          desktop5: "1.2rem",
        }}
        />
        <InfoBox
          icon={GiVillage}
          boxTxt="Offering a large and diverse bundle of features, homes and facilities to ensure there is something for everyone."
          fontSize={{
            base: "0.75rem",
            sm: "1.1rem",
            miniMobile10: "0.6rem",
            tablet16: "1rem",
            desktop5: "1.2rem",
          }}
        />
      </BoxesRow>
      <BoxesRow>
        <InfoBox
          icon={MdOutlineApartment}
          boxTxt="From apartments and duplexes to villas and townhouses all spread alongside the swimming pools, lakes and parks."
          fontSize={{
            base: "0.75rem",
            sm: "1.1rem",
            miniMobile10: "0.6rem",
            tablet16: "1rem",
            desktop5: "1.2rem",
          }}
        />
        <InfoBox
          icon={GiPathDistance}
          boxTxt="Built to offer the utmost of privacy and luxurious living to its residents with over 60% of landscape surrounding each home."
          fontSize={{
            base: "0.75rem",
            sm: "1.1rem",
            miniMobile10: "0.6rem",
            tablet16: "1rem",
            desktop5: "1.2rem",
          }}
        />
      </BoxesRow>
    </VStack>
  );
}

export default memo(PageInfoInBoxes);

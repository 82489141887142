import { Button } from "@chakra-ui/react";
import { useContext } from "react";
import { LocationPageContext } from "../../ProjectLocationPage";

type Props = {
  optionName: string;
  btnStyles?: any;
};

export default function Btn(props: Props) {
  const {setActiveIconName} = useContext(LocationPageContext);

  return (
    <Button
      color="black"
      _active={{
        bg: "transparent",
        color: "#ccb288",
      }}
      _focus={{}}
      _hover={{
        bg: "transparent",
        color: "#ccb288",
      }}
      _groupHover={{
        bg: "transparent",
        color: "white",
      }}
      _groupActive={{
        bg: "transparent",
        color: "white",
      }}
      _groupFocus={{
        bg: "transparent",
        color: "white",
      }}
      fontSize={{
        base: "0.8rem",
        mobile8: "xl",
        tablet13: "3xl",
        tablet16: "4xl",
      }}
      fontWeight="thin"
      bg="transparent"
      {...props.btnStyles} 
      onClick={() => setActiveIconName(props.optionName)} 
    >
      {props.optionName}
    </Button>
  );
}

import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

// Images.
import ZoneTypeD from 'img/sun-capital/Master plan/Horizontal/108 main cover.jpg';
import ZoneTypeE from 'img/sun-capital/Master plan/Horizontal/109 main cover.jpg';

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="108-building"
          planUrl={`${path}/108-building/plan`}
          buildingName="108 Building"
          image={ZoneTypeD}
        />
        <BuildingOption
          typeName="109-building"
          planUrl={`${path}/109-building/plan`}
          buildingName="109 Building"
          image={ZoneTypeE}
        />
      </OptionsRow>
    </OptionsContainer>
  );
}

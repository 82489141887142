import React from "react";

import { AspectRatio, Center } from "@chakra-ui/react";

import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";

import VideoJS from "./VideoJS";

// Custom Hooks.
import { useChoseStyleOnLandscape } from "utils/Selecting";

export default function VideoComp() {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const videoJsOptions: VideoJsPlayerOptions = {
    autoplay: true,
    controls: true,
    // controlBar: false,
    // bigPlayButton: false,
    defaultVolume: 100,
    responsive: true,
    fluid: true,
    loop: true,
    sources: [
      {
        src: "https://eldokan.blob.core.windows.net/saas/Galleria Video 2022-09-08.mp4",
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player: VideoJsPlayer) => {
    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <AspectRatio w="full" h={{ sm: "51%", tablet16: "45%" }} {...styles}>
      <Center w="full" h="full">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </Center>
    </AspectRatio>
  );
}

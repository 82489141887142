import React from 'react';

import LocationIndicator from 'components/sub-components/LocationIndicator/LocationIndicator';
import PageContainerComponent from '../../../../../../components/common/page-container-component/PageContainerComponent';
import styles from './PaymentPage.module.css';
import FloorPlateNavOptions from 'components/common/floor-plate-nav-options/FloorPlateNavOptions';
import { Box,Divider } from '@chakra-ui/react';
function PaymentPage() {
  const labels_Values = [
    { label: 'Building', value: '100' },
    { label: 'GROSS AREA', value: '100' },
    { label: 'Garden', value: 'N/A' },
    { label: 'Pent House Area', value: 'N/A' },
    { label: 'Roof Area', value: 'N/A' },
    { label: 'Total Apartment', value: '130' },
    { label: 'Starting from', value: '2,310,715' },
    { label: '10% Deposit', value: '231,072' },
    { label: '10% After 2 Years', value: '231,072' },
    { label: '7 years', value: '264,082' },
    { label: 'Maintenance', value: '231,072' },
    { label: 'Club House', value: '100,000' },
    { label: 'Status', value: 'Available' },
    { label: 'Finishing', value: 'Core & Shell' },
    { label: 'Delivery', value: 'Ready To Go' },
  ];
  return (
    <PageContainerComponent>
      <LocationIndicator text="Cyra Type-6" fontSize={60} />
      <div className={styles.paymentContainer}>
        {labels_Values.map((item, index) => (
                    <>
            <Box className={styles.paymentColumn} key={index}>
              <Box
                as="p"
                className={styles.paymentColumnLabel}
                fontSize={{
                  tablet7: "1.6rem",
                  tablet16: "3rem",
                }}
              >
                {item.label}
              </Box>
              <Box
                as="p"
                className={styles.paymentColumnValue}
                fontSize={{
                  tablet7: "1.6rem",
                  tablet16: "3rem",
                }}
              >
                {item.value}
              </Box>
            </Box>
            <Divider color="brown" />
          </>
        ))}
      </div>
      <FloorPlateNavOptions isPaymentPage isSold={true} />
    </PageContainerComponent>
  );
}

export default PaymentPage;

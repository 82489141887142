import { Box, Center, VStack } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";
import { selectSharingVisibility } from "./sharingSlice";

export default function Container(props: any) {
  const isVisible = useSelector(selectSharingVisibility).isComponentVisible;
  const componentVisibility = isVisible ? undefined : "none";

  // Landscape styling.
  const containerStylesInLandscape = {
    w: {
      "1xsm": "50vw",
    },
    h: {
      "1xsm": "50vh",
    },
    borderRadius: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Box
      display={componentVisibility}
      w="full"
      h="full"
      blur="1rem"
      pos="absolute"
      bottom="0"
      left="0"
      zIndex={50}
      bg="#ffffff50"
      p="4%"
    >
      <Center w="full" h="full">
        <VStack
          w={[
            "full",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            "80%",
            "91%",
            "90%",
          ]}
          h={[
            "25vh",
            "25vh",
            "23%",
            "23%",
            "25%",
            "22%",
            "22vh",
            null,
            "30vh",
            null,
            "22vh",
            null,
          ]}
          bg="white"
          borderRadius={[
            "2vh",
            "0.8rem",
            "0.8rem",
            "0.8rem",
            "0.8rem",
            null,
            null,
            "2rem",
          ]}
          borderWidth={[
            "thin",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            "thick",
          ]}
          borderColor="#CCB288"
          p={["1rem", null, null, null, null, null, null, null, "2rem"]}
          {...styles}
        >
          {props.children}
        </VStack>
      </Center>
    </Box>
  );
}

import { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";

import enquiryIcon from "./inquiry-icon.svg";
import { useHistory } from "react-router-dom";
import { useChoseStyleOnLandscape } from "utils/Selecting";

export const BookDisplayTriggerButton = () => {
  const history = useHistory();

  const [shouldDisplay, setShouldDisplay] = useState(
    isTypePage(history.location.pathname)
  );

  useEffect(() => {
    history.listen((location) => {
      console.log(`You changed the page to: ${location.pathname}`);
      setShouldDisplay(isTypePage(history.location.pathname));
    });
  }, [history]);

  const onClick = () => {
    history.push("/galleria/enquiry-form");
  };

  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      mobile14: "100%",
      mobile15: "100%",
      tablet1: "100%",
      tablet4: "5vw",
      tablet6: "100%",
      desktop8: "80%",
    },
    w: {
      mobile14: "6%",
      mobile15: "6%",
      tablet1: "6%",
      tablet4: "6vw",
      tablet6: "6%",
    },
    borderRadius: {
      base: "0.5rem",
      mobile12: "0.7rem",
      mobile16: "0.9rem",
      tablet1: "0.5rem",
      tablet6: "0.5rem",
      tablet16: "1rem",
    },
    p: {
      base: "0.3rem",
      xsMobile2: "1vh",
      miniMobile6: "0.5rem",
      miniMobile8: "0.4rem",
      miniMobile9: "0.5rem",
      miniMobile10: "0.5rem",
      miniMobile11: "0.5rem",
      miniMobile12: "0.7rem",
      mobile1: "0.6rem",
      mobile4: "0.4rem",
      mobile5: "0.5rem",
      mobile6: "0.5rem",
      mobile7: "0.4rem",
      mobile8: "0.8rem",
      mobile9: "0.7rem",
      mobile10: "0.5rem",
      mobile11: "0.4rem",
      mobile12: "0.7rem",
      mobile14: "0.7rem",
      mobile15: "0.5rem",
      mobile16: "0.8rem",
      tablet1: "0.5rem",
      tablet2: "0.4rem",
      tablet3: "0.5rem",
      tablet4: "0.5rem",
      tablet7: "0.6rem",
      tablet9: "1rem",
      tablet10: "0.6rem",
      tablet13: "0.9rem",
      tablet16: "1rem",
      tablet19: "0.8rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Button
      // Code To Match My Previous Code:
      display={!shouldDisplay ? "none" : undefined}
      h={{
        base: "11vw",
      }}
      w={{
        base: "12vw",
      }}
      p={{
        base: "1rem",
      }}
      bg="transparent"
      borderColor="white"
      borderRadius={{
        base: "0.5rem",
        mobile12: "0.7rem",
        mobile16: "0.9rem",
        lg: "1rem",
      }}
      borderWidth="1px"
      mr="1.5rem"
      {...styles}
      onClick={onClick}
    >
      <img src={enquiryIcon} alt="enquiryIcon" />
    </Button>
  );
};

const isTypePage = (pathname: string) => {
  const urlValues = pathname.split("/");
  const isZone = !!urlValues[3];

  return isZone;
};

import { memo } from "react";

import { HStack } from "@chakra-ui/react";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function BoxesRow(props: Props) {
  return (
    <HStack
      w="full"
      h={{ base: "45%", tablet16: "47%", desktop5: "47.3%" }}
      spacing={{
        base: "1rem",
        sm: "2rem",
        lg: "3rem",
      }}
    >
      {props.children}
    </HStack>
  );
}

export default memo(BoxesRow);

import { Icon } from "@chakra-ui/react";
import { IoMedkit } from "react-icons/io5";
import { IconsNames } from "../../pins/utils";

import SingleOptionButton from "../single-option-button/SingleOptionButton";

const Medical = () => {
  return (
    <SingleOptionButton optionName={IconsNames.Medical}>
      <Icon as={IoMedkit} fontSize="2rem" color="#ccb288" />
    </SingleOptionButton>
  );
};

export default Medical;

import { Box } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

// Images.
import ZoneTypeC from 'img/sun-capital/Master plan/Ugreen/Type Building E/E-1BD main cover.jpg';
import ZoneTypeD from 'img/sun-capital/Master plan/Ugreen/Type Building E/E-2BD main cover.jpg';
import ZoneTypeE from 'img/sun-capital/Master plan/Ugreen/Type Building E/E-S main cover.jpg';

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="E-1BD"
          planUrl={`${path}/E-1BD/plan`}
          buildingName="E-1BD"
          image={ZoneTypeC}
        />
        <BuildingOption
          typeName="E-2BD"
          planUrl={`${path}/E-2BD/plan`}
          buildingName="E-2BD"
          image={ZoneTypeD}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="E-S"
          planUrl={`${path}/E-S/plan`}
          buildingName="E-S"
          image={ZoneTypeE}
        />

        <Box flex="1" h="100%" />
      </OptionsRow>
    </OptionsContainer>
  );
}

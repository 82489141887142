import { memo } from "react";
import { Image } from "@chakra-ui/react";

// Styling
import externalStyles from "./BtnImg.module.css";

// Utils
import { useChoseStyleOnLandscape } from "utils/Selecting";

type Props = {
  image: string;
  imageWhite: string;
  isSelected?: boolean;
  isInfoBtn?: boolean;
};

function BtnImg(props: Props) {
  const ImgStylesInLandscape = {
    h: {
      "1xsm": "50%",
      mobile7: "40%",
      tablet4: "80%",
      tablet10: "75%",
    },
    w: {
      mobile7: "10%",
      tablet4: "22%",
    },
  };
  const ImgStyles = useChoseStyleOnLandscape(ImgStylesInLandscape);

  return (
    <Image
      className={externalStyles.btn_img}
      src={props.isSelected ? props.imageWhite : props.image}
      color="white"
      h={{
        base: "75%",
        miniMobile8: "50%",
        miniMobile9: "40%",
        miniMobile10: "40%",
        mobile8: "40%",
        tablet16: "2.5rem",
      }}
      {...ImgStyles}
    />
  );
}

export default memo(BtnImg);

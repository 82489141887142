import { Switch, Route } from "react-router-dom";

// Pages.
import EnquiryForm from "components/EnquiryForm";
import FloorsPlanPage from "pages/galleria/floors-plan/FloorsPlanPage";
import ProjectGalleryPage from "pages/galleria/gallery/ProjectGalleryPage";
import FacilitiesPage from "pages/galleria/facilities/ProjectFacilitiesPage";
import ProjectLocationPage from "pages/galleria/location/ProjectLocationPage";
import PresentationPage from "pages/galleria/presentation-page/ProjectPresentationPage";

// Nested Routes.
import MasterPlan from "./master-plan";
import Page_360 from "pages/Page-360";

const Index = () => {
  

  return (
    <Switch>
      <Route exact path={`/galleria/presentation`}>
        <PresentationPage />
      </Route>
      <Route exact path={`/galleria/location`}>
        <ProjectLocationPage />
      </Route>
      <Route exact path={`/galleria/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/enquiry-form`}>
        <EnquiryForm  />
      </Route>
     
      <Route exact path={`/galleria/floors-plan`}>
        <FloorsPlanPage />
      </Route>
      <Route exact path={`/galleria/facilities`}>
        <FacilitiesPage />
      </Route>
      <MasterPlan />
    </Switch>
  );
};

export default Index;

// Custom Components
import LocationIndicator from '../../../../../../components/sub-components/LocationIndicator/LocationIndicator';
import PageContainerComponent from '../../../../../../components/common/page-container-component/PageContainerComponent';

// Custom Hooks
import useVerifyAuth from '../../../../../../hooks/navigation/useVerifyAuth';

import { useGetStackTypeElOnPageOrientation } from '../../../../../../utils/Values';

// Utils

// Lazy
import { lazy, memo, Suspense } from 'react';
import LazyLoadingComp from 'components/common/LazyLoadingComp';
import FloorPlateNavOptions from 'components/common/floor-plate-nav-options/FloorPlateNavOptions';

// Lazy Component
const ProjectGallery = lazy(() => import('./ProjectPlan/ProjectPlan'));

function ProjectGalleryPage() {
  // Auth before accessing this page
  useVerifyAuth();

  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  return (
    <PageContainerComponent overflow="hidden">
      <LocationIndicator text={`Cyra Type-2`} />
      <StackType
        w="full"
        h="90%"
        spacing={{ base: '0.5rem', miniMobile10: '0.7rem', tablet16: '2rem' }}
      >
        <Suspense fallback={<LazyLoadingComp />}>
          <ProjectGallery />
        </Suspense>
        <FloorPlateNavOptions isPlanPage />
      </StackType>
    </PageContainerComponent>
  );
}

export default memo(ProjectGalleryPage);

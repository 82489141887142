import { Route } from 'react-router-dom';

import Type2 from './type-2';
import Type5 from './type-5';
import Type6 from './type-6';
import Type7 from './type-7';
import Type8 from './type-8';
import Type9 from './type-9';
import Type10 from './type-10';
import Type14 from './type-14';
import Type16 from './type-16';
import Type18 from './type-18';

// Pages.
import ZonePage from 'pages/sun_capital/master-plane/cyra/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/cyra`}>
        <ZonePage />
      </Route>
      <Type2 />
      <Type5 />
      <Type6 />
      <Type7 />
      <Type8 />
      <Type9 />
      <Type10 />
      <Type14 />
      <Type16 />
      <Type18 />
    </>
  );
};

export default Index;

import { Route } from 'react-router-dom';

import TypeC from '../zone-2/Type-C/index';
import TypeD from '../zone-2/Type-D/index';
import TypeE from '../zone-2/Type-E/index';
import TypeG from '../zone-2/Type-G/index';
import TypeH from '../zone-2/Type-H/index';

// Pages.
import ZonePage from 'pages/galleria/master-plane/zone-2/ProjectZonePage';

const Index = () => {
  return (
    <>
      <Route exact path={`/galleria/master-plan/zone-2`}>
        <ZonePage />
      </Route>
      <TypeC />
      <TypeD />
      <TypeE />
      <TypeG />
      <TypeH />
    </>
  );
};

export default Index;

import { Box } from "@chakra-ui/react";
import { useRouteMatch } from "react-router-dom";

// Custom Components.
import OptionsRow from "./OptionsRow";
import BuildingOption from "./BuildingOption";
import OptionsContainer from "./OptionsContainer";

// Images.
import PropertyTypeC from "img/galleria/master-plan/zone-6/Type C main cover.png";
import PropertyTypeD from "img/galleria/master-plan/zone-6/Type D main cover.png";
import PropertyTypeE from "img/galleria/master-plan/zone-6/Type E main cover.png";
import PropertyTypeG from "img/galleria/master-plan/zone-6/Type G main cover.png";
import PropertyTypeH from "img/galleria/master-plan/zone-6/Type H main cover.png";

type Props = {};

export default function AllBuildings(props: Props) {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="type-c"
          planUrl={`${path}/type-c/plan`}
          buildingName="Type C"
          image={PropertyTypeC}
        />
        <BuildingOption
          typeName="type-d"
          planUrl={`${path}/type-d/plan`}
          buildingName="Type D"
          image={PropertyTypeD}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="type-e"
          planUrl={`${path}/type-e/plan`}
          buildingName="Type E"
          image={PropertyTypeE}
        />
        <BuildingOption
          typeName="type-g"
          planUrl={`${path}/type-g/plan`}
          buildingName="Type G"
          image={PropertyTypeG}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="type-h"
          planUrl={`${path}/type-h/plan`}
          buildingName="Type H"
          image={PropertyTypeH}
        />
        <Box flex="1" h="100%" />
      </OptionsRow>
    </OptionsContainer>
  );
}

import { Route } from 'react-router-dom';

// Pages.
import ProjectPlanPage from 'pages/sun_capital/master-plane/cyra/Type-7/plan/ProjectPlanPage';
import ProjectGalleryPage from 'pages/sun_capital/master-plane/cyra/Type-7/gallery/ProjectGalleryPage';
import PaymentPage from 'pages/sun_capital/master-plane/cyra/Type-7/payment/PaymentPage';
const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/cyra/type-7/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/cyra/type-7/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/cyra/type-7/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

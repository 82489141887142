import { VStack } from "@chakra-ui/react";

import Img from "./Img";
import Txt from "./Txt";

type Props = {};

export default function HeroImage(props: Props) {
  return (
    <VStack w="100%" h="45%" flex={1} spacing={0} marginTop="2rem" marginBottom="2rem">
      <Txt />
      <Img />
    </VStack>
  );
}

import { useContext, useState } from "react";
import { VStack, Image, Box, Text, Flex, Skeleton } from "@chakra-ui/react";

import ViewButton from "./ViewButton";

import { MasterPlanImageContext } from "../ProjectZonePage";

type Props = {
  image: string;
  planUrl: string;
  typeName: string;
  buildingName: string;
};

export default function BuildingOption(props: Props) {
  const { typeName, setTypeName } = useContext(MasterPlanImageContext);

  const display = () => {
    if (typeName === props.typeName) setTypeName("none");
    else setTypeName(props.typeName);
  };

  // Loading logic
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Skeleton
      flex={1}
      h="100%"
      borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "2rem" }}
      display={"flex"}
      isLoaded={isLoaded}
    >
      <VStack
        position="relative"
        w="100%"
        h="100%"
        flex={1}
        spacing={0}
        onClick={display}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          flex={1}
          height={"100%"}
          borderRadius={{ base: "1rem", mobile8: "2rem", tablet16: "1rem" }}
          src={props.image}
        />
        <Box
          w="100%"
          h={{ base: "25%", mobile8: "30%", tablet16: "25%" }}
          position="absolute"
          bottom="0"
          left="0"
          borderBottomRadius={{
            base: "1rem",
            mobile8: "2rem",
            tablet16: "1.8rem",
          }}
          bg="#00000091"
        >
          <Flex
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              pl="1.5rem"
              fontSize={{
                base: "1rem",
                mobile8: "1.5rem",
                tablet2: "2rem",
                tablet16: "5xl",
              }}
              color="white"
            >
              {props.buildingName}
            </Text>
            <ViewButton planUrl={props.planUrl} />
          </Flex>
        </Box>
      </VStack>
    </Skeleton>
  );
}

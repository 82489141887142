import { Box } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';

// Custom Components.
import OptionsRow from './OptionsRow';
import BuildingOption from './BuildingOption';
import OptionsContainer from './OptionsContainer';

// Images.
import PropertyType1 from 'img/sun-capital/Master plan/The Capital Heart/types/4.jpg';
import PropertyType2 from 'img/sun-capital/Master plan/The Capital Heart/types/7.jpg';
import PropertyType3 from 'img/sun-capital/Master plan/The Capital Heart/types/Hany Saad.jpg';
import PropertyType4 from 'img/sun-capital/Master plan/The Capital Heart/types/Perkins Eastman.jpg';
import PropertyType5 from 'img/sun-capital/Master plan/The Capital Heart/types/Roland Garros.jpg';
import PropertyType6 from 'img/sun-capital/Master plan/The Capital Heart/types/Fairmont Hotel.jpg';
import PropertyType7 from 'img/sun-capital/Master plan/The Capital Heart/types/Holiday Inn and Staybridge.jpg';
import PropertyType8 from 'img/sun-capital/Master plan/The Capital Heart/types/Untitled-1-03.jpg';
import PropertyType9 from 'img/sun-capital/Master plan/The Capital Heart/types/Untitled-1-04.jpg';
import PropertyType10 from 'img/sun-capital/Master plan/The Capital Heart/types/Untitled-1-05.jpg';

export default function AllBuildings() {
  const { path } = useRouteMatch();

  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          typeName="Callisonrtk"
          buildingName="CALLISONRTK"
          image={PropertyType1}
        />
        <BuildingOption
          typeName="National Communities Authority"
          buildingName="National Communities Authority"
          image={PropertyType2}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="Hany Saad"
          buildingName="Hany Saad Innovations"
          image={PropertyType3}
        />
        <BuildingOption
          typeName="Perkins Eastman"
          buildingName="Perkins Eastman"
          image={PropertyType4}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="Roland Garros"
          buildingName="Roland Garros Club"
          image={PropertyType5}
        />
        <BuildingOption
          typeName="Fairmont Hotel"
          buildingName="Fairmont Hotel"
          image={PropertyType6}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="Holiday Inn"
          buildingName="Holiday Inn and Staybridge"
          image={PropertyType7}
        />
        <BuildingOption
          typeName="Brand Admin Building"
          buildingName="Brand Admin Building"
          image={PropertyType8}
        />
      </OptionsRow>
      <OptionsRow>
        <BuildingOption
          typeName="Mixed Use"
          buildingName="Mixed Use"
          image={PropertyType9}
        />
        <BuildingOption
          typeName="International School"
          buildingName="International School"
          image={PropertyType10}
        />
      </OptionsRow>
      {/* <OptionsRow>
        <Box flex="1" h="100%" />
      </OptionsRow> */}
    </OptionsContainer>
  );
}

import { memo } from "react";

import { Button } from "@chakra-ui/react";

type Props = {
  isSelected?: boolean;
  name: string;
  isInfoBtn?: boolean;
};

function Btn(props: Props) {
  return (
    <Button
      _groupActive={{
        color: "white",
      }}
      h="full"
      w="full"
      color={props.isSelected ? "white" : "#CCB288"}
      fontSize={{
        sm: "1.4rem",
        tablet16: "1.9rem",
      }}
      fontWeight={700}
      bg="transparent"
      _active={{
        bg: "transparent",
      }}
      _hover={{
        bg: "transparent",
      }}
      _focus={{
        outline: "none",
      }}
    >
      {props.name}
    </Button>
  );
}

export default memo(Btn);

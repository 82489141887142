import { VStack } from "@chakra-ui/react";

import { useAnimation } from "framer-motion";

import { memo, useEffect } from "react";

import { useSelector } from "react-redux";
import { selectMenuAnimation } from "components/common/NavigationMenu/NavigationMenuSlice";

import MotionVStack from "components/animated-components/MotionVStack";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function Container(props: Props) {
  const rootContainerStyles = {
    spacing: {
      base: "0.5rem",
      tablet16: "1.2rem",
    },
  };

  const isMenuShown = useSelector(selectMenuAnimation);

  const controls = useAnimation();

  useEffect(() => {
    if (isMenuShown) {
      controls.start({
        opacity: [1, 0],
        transition: {
          duration: 1,
        },
      });
    } else {
      controls.start({
        opacity: [0, 1],
        transition: {
          duration: 1,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuShown]);

  return (
    <VStack
      as={MotionVStack}
      animate={controls}
      w="100%"
      h="20%"
      spacing={rootContainerStyles.spacing}
    >
      {props.children}
    </VStack>
  );
}

export default memo(Container);

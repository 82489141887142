import { Switch, Route, useRouteMatch } from "react-router-dom";

// Pages.
import ProjectPlanPage from "pages/galleria/master-plane/zone-1/Type-D/plan/ProjectPlanPage";
import ProjectGalleryPage from "pages/galleria/master-plane/zone-1/Type-D/gallery/ProjectGalleryPage";
import PaymentPage from "pages/galleria/master-plane/zone-1/Type-D/payment/PaymentPage";
const Index = () => {
  const { path } = useRouteMatch();
  return (
    <>
     
      <Route exact path={`/galleria/master-plan/zone-1/type-d/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-1/type-d/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/galleria/master-plan/zone-1/type-d/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;

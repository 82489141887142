import { Route } from "react-router-dom";

// Pages.
import ProjectPlanPage from "pages/sun_capital/master-plane/Hani Saad/Townhouse/plan/ProjectPlanPage";
import ProjectGalleryPage from "pages/sun_capital/master-plane/Hani Saad/Townhouse/gallery/ProjectGalleryPage";
import PaymentPage from "pages/sun_capital/master-plane/Hani Saad/Townhouse/payment/PaymentPage";
const Index = () => {
  return (
    <>
      <Route exact path={`/sun_capital/master-plan/hani-saad/townhouse/plan`}>
        <ProjectPlanPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/hani-saad/townhouse/gallery`}>
        <ProjectGalleryPage />
      </Route>
      <Route exact path={`/sun_capital/master-plan/hani-saad/townhouse/payment`}>
        <PaymentPage />
      </Route>
    </>
  );
};

export default Index;
